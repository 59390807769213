import { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { api } from '../services/api'

import ProfileTabs from '../components/ProfileTabs'
import ProfileHeader from '../components/profile/ProfileHeader'
import { BackButton } from '../components/buttons/BackButton'

import { selectIsAdmin, selectUser } from '../data/user/selectors'
import { IUser } from '../types/modelTypes'
import { useAppSelector } from '../data/reduxHooks'

export default function Profile() {
  const params = useParams<{ id: string }>()
  const alert = useAlert()
  const history = useHistory()

  const [user, setUser] = useState<IUser | null>(null)
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [forceRefresh, setForceRefresh] = useState(false)

  const isAdmin = useAppSelector(selectIsAdmin)
  const currentUser = useSelector(selectUser)

  const fetchUser = async () => {
    try {
      const { data } = await api.User.getByID({ id: params.id })
      setUser(data)
    } catch (error: any) {
      alert.error(error.message)
    }
  }

  useEffect(() => {
    // admins can view any user's profile
    if (params.id && isAdmin) {
      fetchUser()
    } else if (currentUser) {
      setUser(currentUser)
    } else {
      // alert.error('You have been logged out. Please log in again.')
      // history.push('/login')
    }
  }, [params, isAdmin, currentUser])

  function goBack() {
    history.goBack()
  }

  function handleOnNewListAddComplete() {
    setForceRefresh(!forceRefresh)
  }

  if (!user) {
    return null
  }
  return (
    <>
      <div className="flex h-full flex-col px-8 sm:px-16">
        <main className="relative z-0 flex-1 focus:outline-none">
          <article className="overflow-hidden">
            <nav className="flex items-start py-3" aria-label="Breadcrumb">
              <BackButton text="Go back" onClick={goBack} />
            </nav>
            <ProfileHeader
              user={user}
              activeTabIndex={activeTabIndex}
              onNewListAddComplete={handleOnNewListAddComplete}
            />
          </article>

          {/* Tabs */}
          <div className="mt-6 sm:mt-2 2xl:mt-5">
            <div className="mx-auto px-4 sm:px-6 lg:px-8">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                <ProfileTabs
                  history={history}
                  user={user}
                  onTabChange={setActiveTabIndex}
                  forceFetch={forceRefresh}
                />
              </nav>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}
