import { createSelector } from '@reduxjs/toolkit'

export const selectFilterString = createSelector(
  (state) => state.globals.filters,
  (filters) => {
    return filters
      .map((f) => {
        if (!f || !f.prop || !f.operator) {
          // if filter has no property value or operator value, it's invalid
          return false
        }
        if (f.attr?.length === 0 && f.operator?.value !== 'none' && f.operator?.value !== 'any') {
          // all operators besides 'any' and 'none' require attributes
          return false
        }

        return `${f.prop?.value}:{${f.attr.map((a) => a.value).join('|')}}:${f.operator?.value}:${
          f.andOr
        }`
      })
      .filter((f) => f)
  },
)
