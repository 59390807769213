import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentSlide } from '../data/decks/selectors'

import constants from '../utils/constants'
import { formatter } from '../utils/helpers'

export function useComputedBudget() {
  const { currentSlide } = useSelector(selectCurrentSlide)
  const master = useSelector((state) => state.decks)
  const [computedBudget, setComputedBudget] = useState(0)

  useEffect(() => {
    const weeks =
      currentSlide.type === constants.CONTINUITY_TYPES.QUAD
        ? 4
        : currentSlide.type === constants.CONTINUITY_TYPES.QUINT
        ? 5
        : 1
    const budget = master.budget

    const totalCost = currentSlide.products?.reduce((prev, curr) => {
      return prev + Number(curr.cost || 0)
    }, 0)

    if (totalCost === 0) {
      setComputedBudget(formatter.format(budget * weeks))
      return
    }
    // formula to caluclate remaining budget
    // weeklyBudget * weeks * (1 - ((1 - priceModifier) * 100) / 100) - totalCost
    const remaining =
      budget * weeks * (1 - ((1 - currentSlide.priceModifier) * 100) / 100) - totalCost
    // rount up to nearest 10 cents value
    const modified = formatter.format(remaining)
    setComputedBudget(modified)
  }, [currentSlide, master])

  return { budgetRemaining: computedBudget }
}
