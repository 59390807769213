import { store } from '../store'
import {
  setFilters as sf,
  setCrumbs as sc,
  setSearchTerm as sst,
  setCurrentList as scl,
  setCurrentDirectory as scd,
  addPriceAdjustOption as apao,
  resetPriceAdjustOptions as rpa,
  setCurrentProductSort as scps,
  clearState as cs,
  removeProductFromList as rpfl,
  removeProductsFromList as rpsfl,
} from './globalsSlice'

export const setFilters = (data) => store.dispatch(sf(data))

export const setCrumbs = (data) => store.dispatch(sc(data))

export const setSearchTerm = (data) => store.dispatch(sst(data))

export const setCurrentList = (data) => store.dispatch(scl(data))

export const setCurrentDirectory = (data) => store.dispatch(scd(data))

export const addPriceAdjustOption = (data) => store.dispatch(apao(data))

export const resetPriceAdjustOptions = () => store.dispatch(rpa())

export const setCurrentProductSort = (pageNum) => store.dispatch(scps(pageNum))

export const clearState = () => store.dispatch(cs())

export const removeProductFromList = (listId, productId) =>
  store.dispatch(rpfl({ listId, productId }))

export const removeProductsFromList = (listId, productIds) =>
  store.dispatch(rpsfl({ listId, productIds }))
