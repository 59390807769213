import { useSelector } from 'react-redux'
import { formatter } from '../../utils/helpers'
// import { selectIsAdmin } from '../../data/user/selectors'
import { ButtonSquare } from './ButtonSquare'

const requiredKeyTranslations = {
  manufacturerId: 'Product Id/Manufacturing Id',
  brand: 'Brand',
  description: 'Description',
  name: 'Product Name',
  vendor: 'Vendor',
  color: 'Color',
  category: 'Category',
  tags: 'Tags',
  inventory: 'Current Inventory',
  cost: 'Cost',
  price: 'Price',
  msrp: 'msrp',
  computedPrimaryImage: 'Image URL',
}

export default function ExportCsvButton({ className }) {
  const deck = useSelector((state) => state.decks)

  function exportJSONToCSV(objArray) {
    var arr = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray
    var headerStr =
      `${Object.keys(arr[0])
        .map((value) => `"${value}"`)
        .join(',')}` + '\r\n'
    var csvContent = arr.reduce((st, next) => {
      st +=
        `${Object.values(next)
          .map(
            (value) =>
              `"${`${value ? value : ''}`
                .replace(/"/g, '\u201d') // escape any double quotes in string
                .replace(/#/g, '') // problematic character
                .replace(/\*/g, '') // problematic character
                .replace(/[\n\r]+/g, '')}"`, // escape any new lines in string
          )
          .join(',')}` + '\r\n'
      console.log('ST', st)
      return st
    }, headerStr)
    var element = document.createElement('a')
    element.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvContent)
    element.target = '_blank'
    element.download = 'export.csv'
    element.click()
  }

  function onExport() {
    let products = []
    products = deck.continuities
      // products need their price calculated
      .map((c) => {
        return (
          c.products?.map((p) => {
            return {
              ...p,
              price: formatter.format(Number(p.cost) / (c.priceModifier || 1)),
            }
          }) || null
        )
      })
      // title slides do not have products so filter those out
      .filter((p) => !!p)
      .flat(1)
      .filter((p) => p.hasOwnProperty('productId'))
      .map((p) => {
        let translated = {}
        for (let key in requiredKeyTranslations) {
          translated[requiredKeyTranslations[key]] = p[key]
        }
        return translated
      })
    // const crazy = Array(4000)
    //   .fill()
    //   .map((_) => products[0])
    exportJSONToCSV(products)
  }

  return (
    <ButtonSquare
      text="EXPORT TO CSV"
      onClick={onExport}
      className={`h-9 justify-center text-[12px] sm:py-0 md:px-2 md:text-sm ${className}`}
    />
  )
}
