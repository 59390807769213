/* eslint-disable react/display-name */
import { Tab } from '@headlessui/react'

import { classNames } from '../../utils/helpers'
import BackgroundImageModalPassportTab from './BackgroundImagePassportTab'
import BackgroundImageModalUnsplashTab from './BackgroundImageUnsplashTab'

export const BackgroundImageTabs = ({ onSelect }) => {
  const tabs = {
    Passport: {
      render: () => <BackgroundImageModalPassportTab onSelect={onSelect} />,
    },
    Unsplash: {
      render: () => <BackgroundImageModalUnsplashTab onSelect={onSelect} />,
    },
  }

  return (
    <div className="h-full w-full px-2 pb-2 sm:px-0">
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl p-1">
          {Object.keys(tabs).map((category) => (
            <Tab
              key={category}
              className={({ selected }) =>
                classNames(
                  selected
                    ? 'border-purple-500 text-gray-900'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 py-2 pl-1 pr-8 text-left text-sm font-medium outline-none ring-0 focus:outline-none',
                )
              }
            >
              {category}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="h-full">
          {Object.values(tabs).map((tab, idx) => (
            <Tab.Panel
              key={idx}
              className={classNames('bg-white', 'h-full focus:outline-none focus:ring-0')}
            >
              {tab.render()}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}
