import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { api } from '../../services/api'
import { REMOVE_FAVORITES, ADD_FAVORITES, GET_ALL } from './types'

export const removeFavorites = createAsyncThunk(REMOVE_FAVORITES, async (productIds, thunkAPI) => {
  try {
    const { user } = thunkAPI.getState()
    const { data } = await api.List.removeFromMyFavorites(user.id, productIds)
    return data
  } catch (error) {
    thunkAPI.rejectWithValue(error.message)
  }
})

export const addFavorites = createAsyncThunk(ADD_FAVORITES, async (productIds, thunkAPI) => {
  try {
    const { user } = thunkAPI.getState()
    const { data } = await api.List.addToMyFavorites(user.id, productIds)
    return data
  } catch (error) {
    thunkAPI.rejectWithValue(error.message)
  }
})

export const getAll = createAsyncThunk(GET_ALL, async (data, thunkAPI) => {
  try {
    const { user } = thunkAPI.getState()
    const { data } = await api.User.getFavorites(user.id)
    return data
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message)
  }
})

const initialState = []

export const favoritesSlice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {
    updateFavorites: (state, action) => {
      return action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(removeFavorites.fulfilled, (state, action) => {
        return action.payload
      })
      .addCase(addFavorites.fulfilled, (state, action) => {
        return action.payload
      })
      .addCase(getAll.fulfilled, (state, action) => {
        return action.payload
      })
  },
})

// Action creators are generated for each case reducer function
export const { updateFavorites } = favoritesSlice.actions

export default favoritesSlice.reducer
