import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ISelectionsInitialState, SelectedProducts, SelectedSlides } from './types'
import { IContinuity, IProduct } from '../../types/modelTypes'

const initialState: ISelectionsInitialState = {
  selectedProducts: {},
  multiSelectOption: null,
  selectedSlides: {},
}

export const selectionsSlice = createSlice({
  name: 'selections',
  initialState,
  reducers: {
    updateMultiSelect: (state, action) => {
      state.multiSelectOption = action.payload
    },
    updateSelectedProducts: (state, action: PayloadAction<SelectedProducts>) => {
      state.selectedProducts = action.payload
    },
    updateSelectedSlides: (state, action: PayloadAction<SelectedSlides>) => {
      state.selectedSlides = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateMultiSelect, updateSelectedProducts, updateSelectedSlides } =
  selectionsSlice.actions

export default selectionsSlice.reducer
