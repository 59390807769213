import { useState } from 'react'
import { Link } from 'react-router-dom'
import { DocumentIcon, FolderIcon, TrashIcon } from '@heroicons/react/solid'

export const MyContinuitiesCard = ({ item, onClick, onDelete }) => {
  const [hoverActive, setHoverActive] = useState(false)

  function handleItemClick(e) {
    e.preventDefault()
    e.stopPropagation()
    onClick(item)
  }

  function onMouseEnter() {
    setHoverActive(true)
  }

  function onMouseLeave() {
    setHoverActive(false)
  }

  function handleDelete(e) {
    e.preventDefault()
    e.stopPropagation()
    onDelete(item)
  }

  return (
    <section
      className="h-full cursor-pointer"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={handleItemClick}
    >
      <li className="relative flex flex-col justify-between shadow-full-light hover:shadow-full-dark">
        {item.type === 'folder' ? (
          <FolderIcon className="mx-auto h-36 w-36 text-gray-200" />
        ) : item.continuities?.[0]?.thumbnail ? (
          <div className="flex h-36 w-full items-center">
            <img src={item.continuities[0]?.thumbnail} className="h-20 w-full object-contain" />
          </div>
        ) : (
          <DocumentIcon className="mx-auto h-36 w-36 text-gray-200" />
        )}
        <div className="flex h-[70px] items-end justify-between p-5 pt-0">
          <div className="flex flex-col justify-end">
            <Link
              to={`/products/${item.id}`}
              className="line-clamp-2 pr-1 text-sm font-semibold text-gray-900"
            >
              {item.name || item.filename || item.title || 'Unknown'}
            </Link>
          </div>
          {hoverActive && (
            <TrashIcon
              onClick={handleDelete}
              className="absolute bottom-2 right-2 z-10 h-5 w-5 cursor-pointer text-red-500"
            />
          )}
        </div>
      </li>
    </section>
  )
}
