import { useEffect, useState } from 'react'

import { formatter, roundUp } from 'utils/helpers'
import { updateCurrentSlide } from 'data/decks'
import { ContinuityType, IContinuity } from 'types/modelTypes'

export function useComputedPrice(slide: IContinuity) {
  const priceModifier = Number(slide.priceModifier)

  const [computedPrice, setComputedPrice] = useState('0')

  useEffect(() => {
    const weeks =
      slide.type === ContinuityType.quad || slide.type === ContinuityType.quad_horizontal
        ? 4
        : slide.type === ContinuityType.quint
        ? 5
        : 1
    let totalCost =
      slide?.products?.reduce((prev, curr) => {
        return prev + Number(curr?.cost ?? 0)
      }, 0) / weeks

    // rount up to nearest 10 cents value
    const modifiedCostPerWeek = formatter.format(roundUp(totalCost / priceModifier))
    setComputedPrice(modifiedCostPerWeek)
    if (totalCost > 0) {
      // we don't want this hook to cause a dirty state by setting a currentContinuity when there is no price
      updateCurrentSlide({ price: modifiedCostPerWeek })
    }
  }, [slide?.products, priceModifier])

  return { computedPrice, priceModifier }
}
