import { DotsVerticalIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import { useAlert } from 'react-alert'
import { useHistory } from 'react-router-dom'

import { ButtonSquare } from '../buttons/ButtonSquare'
import { MenuDropdown } from '../MenuDropdown'

import { createUser, resendConfirmationEmail, resetUserPassword } from '../../services/admin'
import { generateInitials } from '../../utils/helpers'
import constants from '../../utils/constants'

export const UserListItem = ({ user, onManageClick, onDisable, onDelete, allowReinvite }) => {
  const alert = useAlert()
  const history = useHistory()

  const [isActive, setIsActive] = useState(false)

  function handleView() {
    history.push(`/profile/${user.id}`)
  }

  function handleManage() {
    // e.preventDefault()
    // e.stopPropagation()
    onManageClick(user)
  }

  function handleMouseEnter() {
    setIsActive(true)
  }

  function handleMouseLeave() {
    setIsActive(false)
  }

  async function handleReinvite(e) {
    e.preventDefault()
    e.stopPropagation()
    try {
      await createUser(user.email, user.name, user.role, true)
      // const resp = await resetUserPassword(user.uuid)
      alert.success('Email sent')
    } catch (error) {
      console.log('E', error)
      alert.error(error.message || 'Error sending email')
    }
  }

  const SELECTED_OPTIONS = [
    { id: 1, label: 'Manage', handler: handleManage },
    { id: 2, label: 'View', handler: handleView },
  ]

  if (user.status === constants.USER_STATUS.INACTIVE) {
    SELECTED_OPTIONS.push({
      id: 3,
      label: 'Delete User',
      labelColor: 'text-red-500',
      handler: () => onDelete(user),
    })
  } else {
    SELECTED_OPTIONS.push({
      id: 3,
      label: 'Disable User',
      labelColor: 'text-red-500',
      handler: () => onDisable(user),
    })
  }

  return (
    <li
      className={`mx-2 flex cursor-pointer items-center justify-between px-4 py-4 ${
        isActive ? 'shadow-full-dark' : 'shadow-full-light'
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div onClick={handleView} className="flex min-w-0 flex-1 items-center space-x-3">
        <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-300 text-sm text-white">
          {generateInitials(user)}
        </div>
        <div className="min-w-0 flex-1">
          <p className="truncate text-xs font-medium text-gray-900">{user.name}</p>
          <p className="truncate text-xxs font-medium text-gray-400">
            {user.organization?.name || 'unorganised'}
          </p>
        </div>
      </div>
      <div className="flex flex-shrink-0 items-center">
        {isActive && allowReinvite && (
          <ButtonSquare
            className="whitespace-nowrap text-xxs"
            small
            text="Re-invite"
            onClick={handleReinvite}
          />
        )}
        <MenuDropdown
          icon={() => (
            <DotsVerticalIcon className="h-6 w-6 rounded-full p-1 text-gray-800 hover:bg-gray-100" />
          )}
          options={SELECTED_OPTIONS}
          onChange={() => {}}
        />
      </div>
    </li>
  )
}
