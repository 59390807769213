import { useState } from 'react'
import { MenuAlt2Icon } from '@heroicons/react/outline'
import { PlusIcon } from '@heroicons/react/solid'
import { useLocation } from 'react-router-dom'

import { BuilderControls } from 'components/continuityBuilder/Controls'
import { BuilderCore } from 'components/continuityBuilder/BuilderCore'
import BuilderCoreNotes from 'components/continuityBuilder/BuilderCoreNotes'
import Thumbnails from 'components/continuityBuilder/Thumbails'
import { Drawer } from 'components/Drawer'
import { NavBar } from 'components/NavBar'

import { addSlide } from 'data/decks'
import { selectCurrentSlide } from 'data/decks/selectors'
import { useAppSelector } from 'data/reduxHooks'

export default function Builder() {
  const { state } = useLocation<undefined | { from?: 'product_detail' }>()
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [sidebarOpenRight, setSidebarOpenRight] = useState(true)
  const { currentSlide } = useAppSelector(selectCurrentSlide)

  return (
    <main className="h-screen">
      <NavBar builder={true} />
      <div style={{ paddingTop: '67px', position: 'relative' }}>
        <Drawer open={sidebarOpen} initialState={true} onOpenStateChange={setSidebarOpen}>
          <button
            onClick={addSlide}
            style={{ height: '90px', width: '160px' }}
            className="flex h-full w-full flex-col items-center justify-center rounded border"
          >
            <PlusIcon className="h-12 w-12 text-gray-400" />
          </button>
          <Thumbnails />
        </Drawer>

        {/* Static left sidebar for desktop */}
        <div
          style={{ paddingTop: '67px' }}
          className="z-10 hidden md:fixed md:inset-y-0 md:flex md:w-60 md:flex-col"
        >
          <div className="scrollbar flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-white pt-5">
            <div className="mt-5 flex flex-grow flex-col">
              <nav id="thumbs" className="flex flex-col items-center space-y-1 px-2 pb-4">
                <button
                  onClick={addSlide}
                  style={{ height: '90px', width: '160px' }}
                  className="flex h-full w-full flex-col items-center justify-center rounded border"
                >
                  <PlusIcon className="h-12 w-12 text-gray-400" />
                </button>
                <Thumbnails />
              </nav>
            </div>
          </div>
        </div>

        <Drawer
          right={true}
          open={sidebarOpenRight}
          initialState={true}
          onOpenStateChange={setSidebarOpenRight}
        >
          <BuilderControls />
        </Drawer>

        {/* Static right sidebar for desktop */}
        <div
          style={{ paddingTop: '67px' }}
          className="right-0 z-20 hidden shadow-full-light md:fixed md:inset-y-0 md:flex md:w-60 md:flex-col"
        >
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="scrollbar flex flex-grow flex-col overflow-y-auto border-l border-gray-200 bg-white">
            <div className="flex flex-grow flex-col">
              <nav className="flex-1 space-y-1 px-2 py-4">
                <BuilderControls />
              </nav>
            </div>
          </div>
        </div>

        <div className="md:px-60">
          <div className="mx-auto flex flex-col">
            <div className="sticky top-0 flex h-16 flex-shrink-0 justify-between bg-white">
              <button
                type="button"
                className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                onClick={() => setSidebarOpen(!sidebarOpen)}
              >
                <span className="sr-only">Open sidebar</span>
                <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
              </button>
              <button
                type="button"
                className="border-l border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                onClick={() => setSidebarOpenRight(!sidebarOpenRight)}
              >
                <span className="sr-only">Open right sidebar</span>
                <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>

            {/* Center content */}
            <main
              className="mx-auto flex w-full justify-center px-5"
              style={{ maxWidth: '1100px' }}
            >
              <BuilderCore showAddProductsModalOnLoad={state?.from === 'product_detail'} />
            </main>
            {/* Notes below builder */}
            {currentSlide && currentSlide.type !== 'title' ? (
              <BuilderCoreNotes slide={currentSlide} deckTitle={'Deck'} />
            ) : null}
          </div>
        </div>
      </div>
    </main>
  )
}
