import { ChangeEvent, useEffect, useState } from 'react'
import { SearchIcon, XCircleIcon } from '@heroicons/react/solid'

// Data
import { setSearchTerm } from '../../data/globals'
import { useAppSelector } from '../../data/reduxHooks'

interface ISearchInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  onChange: (value: string) => void
  className?: string
  useCache?: boolean
  placeholder?: string
  type?: string
}
export const SearchInput = ({
  onChange,
  className,
  useCache,
  placeholder,
  type = 'text',
  ...rest
}: ISearchInputProps) => {
  const cachedSearchTerm = useAppSelector((state) => state.globals.searchTerm)
  const [textValue, setTextValue] = useState('')

  useEffect(() => {
    if (useCache) {
      setTextValue(cachedSearchTerm)
      onChange(cachedSearchTerm)
    }
  }, [useCache])

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const val = e.target.value
    onChange(val)
    if (useCache) {
      setSearchTerm(val)
    } else {
      setTextValue(val)
    }
  }

  function clear() {
    if (useCache) {
      setSearchTerm('')
    } else {
      setTextValue('')
    }
    onChange('')
  }

  return (
    <div className={`relative shadow-sm ${className}`}>
      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <SearchIcon className="h-5 w-5 text-gray-700" aria-hidden="true" />
      </div>
      <input
        type={type}
        name="search"
        className="block w-full border-none bg-gray-100 pl-10 text-sm focus:outline-none focus:ring-0"
        placeholder={placeholder || 'Search'}
        onChange={handleChange}
        autoFocus={true}
        value={useCache ? cachedSearchTerm : textValue}
        {...rest}
      />
      {(textValue || cachedSearchTerm) && (
        <button
          onClick={clear}
          className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3 outline-none focus:outline-none"
        >
          <XCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </button>
      )}
    </div>
  )
}
