import { Fragment, useState, useEffect, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useAlert } from 'react-alert'

import { ButtonSquare } from '../buttons/ButtonSquare'
import { ImageUploadButton } from '../buttons/ImageUploadButton'
import { TextInput } from '../inputs/TextInput'
import OrganizationModalPriceTierRow from './OrganizationModalPriceTierRow'
import ProductAccessModal from './ProductAccessModal'
// import CSVUploader from './CSVUploader'

import { useStateSafe } from '../../hooks/useStateSafe'

import { api } from '../../services/api'
import constants from '../../utils/constants'

const settings = [
  {
    name: 'All Products',
    description: `Selecting "All Products" will give all members of this organization access to all Passport filter features and products.`,
    value: false,
  },
  {
    name: 'GPS Exclusives Only',
    description: `Selecting "GPS Exclusives Only" will give all members of this organization access to GPS exclusive products, and remove access to all other filters and products.`,
    value: true,
  },
]

export const NewOrganizationModal = ({
  open = false,
  onClose,
  onAddComplete,
  editMode = false,
  org,
}) => {
  const alert = useAlert()

  const [selected, setSelected] = useState(settings[0])
  const [loading, setLoading] = useStateSafe(false)
  const [name, setName] = useState(org?.name ?? '')
  const [logoUrl, setLogoUrl] = useState(org?.logoUrl ?? '')
  const [tier1, setTier1] = useState(org?.tier1 ?? 0.3)
  const [tier2, setTier2] = useState(org?.tier2 ?? 0.3)
  const [tier3, setTier3] = useState(org?.tier3 ?? 0.3)
  const [tier4, setTier4] = useState(org?.tier4 ?? 0.3)
  const [tier5, setTier5] = useState(org?.tier5 ?? 0.3)
  const [allAccess, setAllAccess] = useState(org?.allAccess ?? true)
  const [showAccessModal, setShowAccessModal] = useState(false)

  const productIdsForOrg = useRef(null)

  useEffect(() => {
    if (org) {
      setSelected(settings.find((s) => s.value === org.exclusivesOnly))
      setName(org.name)
      setLogoUrl(org.logoUrl)
      setTier1(org.tier1)
      setTier2(org.tier2)
      setTier3(org.tier3)
      setTier4(org.tier4)
      setTier5(org.tier5)
    }
  }, [org])

  async function updateOrganization() {
    setLoading(true)
    try {
      const { data } = await api.Organizations.update(org.id, {
        name,
        tier1: parseFloat(tier1),
        tier2: parseFloat(tier2),
        tier3: parseFloat(tier3),
        tier4: parseFloat(tier4),
        tier5: parseFloat(tier5),
        exclusivesOnly: selected.value,
        logoUrl,
        allAccess,
      })
      setLoading(false)
      alert.success('Organization updated successfully')
      onAddComplete(data)
      onClose()
    } catch (error) {
      setLoading(false)
      alert.error(error.message)
    }
  }

  async function createOrganization() {
    setLoading(true)
    try {
      const { data } = await api.Organizations.create({
        name,
        tier1: parseFloat(tier1),
        tier2: parseFloat(tier2),
        tier3: parseFloat(tier3),
        tier4: parseFloat(tier4),
        tier5: parseFloat(tier5),
        exclusivesOnly: selected.value,
        logoUrl,
        allAccess,
      })
      if (productIdsForOrg.current) {
        await api.Organizations.updateProducts(data.id, {
          ids: productIdsForOrg.current,
        })
      }
      setLoading(false)
      onAddComplete(data)
      onClose()
    } catch (error) {
      setLoading(false)
      alert.error(error.message)
    }
  }

  // async function handleMarkupChange(val) {
  //   const asPercentage = (100 - Number(val)) / 100
  //   setMarkup({ text: val, value: asPercentage })
  // }

  function updateTier(value, tier) {
    // const minMax = Math.max(1, Math.min(100, Number(value)))
    const minMax = Math.min(100, Number(value))
    const val = toPercentage(minMax)
    switch (tier) {
      case 1:
        setTier1(val)
        break
      case 2:
        setTier2(val)
        break
      case 3:
        setTier3(val)
        break
      case 4:
        setTier4(val)
        break
      case 5:
        setTier5(val)
        break
      default:
        return
    }
  }

  async function handleUploadComplete(imageObj) {
    const url = `${process.env.REACT_APP_BUCKET_BASE_URL}/public/${imageObj.key}`
    setLogoUrl(url)
  }

  async function onAllAccess() {
    if (!org.id) {
      setAllAccess(true)
      return
    }
    if (org.id) {
      try {
        await api.Organizations.update(org.id, {
          allAccess: true,
        })
        setAllAccess(true)
        alert.success('All products available to organization')
      } catch (error) {
        alert.error(error.message)
      }
    }
  }

  function onCustomAccess() {
    setShowAccessModal(true)
  }

  async function handleSetProducts(products) {
    if (!org?.id) {
      // we are creating a new org
      productIdsForOrg.current = products.map((p) => p.id)
      setAllAccess(false)
      setShowAccessModal(false)
      return
    }
    try {
      await api.Organizations.updateProducts(org.id, {
        ids: products.map((p) => p.id),
      })
      setAllAccess(false)
      setShowAccessModal(false)
      alert.success(`${products.length} products available to organization`)
    } catch (error) {
      alert.error(error.message)
    }
  }

  function handleSubmit(e) {
    e.preventDefault()
    if (editMode && org) {
      updateOrganization()
    } else {
      createOrganization()
    }
  }

  function toInt(val) {
    return val ? parseInt(val * 100) : ''
  }

  function toPercentage(val) {
    return val / 100
  }

  async function handleCSVJson(error, data) {
    if (error) {
      alert.error(error.message || 'Error parsing csv file')
      return
    }
    try {
      await api.Organizations.bulkCreate(data)
      alert.show('Success!')
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          auto-reopen="true"
          className="scrollbar fixed inset-0 z-30 overflow-y-auto"
          onClose={onClose}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="transition-opacity fixed inset-0 bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="min-w-90/100 transition-all inline-block max-h-80vh transform overflow-y-scroll rounded-sm bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl sm:p-6 sm:align-middle md:min-w-1/2">
                <div className="flex items-start justify-between">
                  <h3 className="text-xl font-bold">Manage Organization</h3>
                  {/* <CSVUploader onParseComplete={handleCSVJson} /> */}
                </div>
                <form onSubmit={handleSubmit} className="mt-4 flex flex-col justify-between">
                  <div className="mb-2 flex items-center">
                    {logoUrl ? (
                      <img src={logoUrl} alt="Organization logo" className="h-20 w-20 border" />
                    ) : (
                      <div className="h-20 w-20 bg-gray-100" />
                    )}
                    <div className="mx-4 flex flex-col">
                      {/* {org?.name && <h3 className="mb-2">{org.name}</h3>} */}
                      <h3 className="mb-2">{name || 'Org Name'}</h3>
                      <div className="flex items-center">
                        <ImageUploadButton
                          disabled={loading}
                          text="Add Photo"
                          folder="organizations/"
                          onUploadComplete={handleUploadComplete}
                          small
                          className="mr-4 whitespace-nowrap border-purple-500 text-sm hover:bg-gray-100"
                          outline={true}
                          resizeOptions={{ maxHeight: 200, maxWidth: 200, quality: 0.7 }}
                          textColor="purple"
                        />
                      </div>
                    </div>
                  </div>
                  <TextInput
                    label="Organization Name"
                    onChange={setName}
                    placeholder="Name"
                    srOnly={false}
                    value={name}
                  />
                  <div className="h-4" />

                  <h3 className="mt-4 text-sm font-semibold">Organization Product Library</h3>
                  <div className="my-2 flex">
                    <ButtonSquare
                      type="button"
                      className="mr-2 h-9 w-32 whitespace-nowrap py-1 text-sm"
                      text="All Products"
                      color={`${allAccess ? 'bg-green' : 'bg-purple'}`}
                      onClick={onAllAccess}
                    />
                  </div>
                  <p className="text-sm text-gray-500">
                    Selecting &quot;All Products&quot; will give all members of this organization
                    access to all Passport products.
                  </p>

                  <div className="my-2 flex">
                    <ButtonSquare
                      type="button"
                      className="mr-2 h-9 w-32 whitespace-nowrap py-1 text-sm text-white"
                      text="Custom"
                      color={`${allAccess ? 'bg-purple' : 'bg-green'}`}
                      onClick={onCustomAccess}
                    />
                  </div>
                  <p className="mb-8 text-sm text-gray-500">
                    Customize what products members of this organization are able to access through
                    Passport.
                  </p>

                  {/* <RadioGroup value={selected} onChange={setSelected}>
                    <RadioGroup.Label className="text-sm font-semibold text-gray-900">
                      Organization Settings
                    </RadioGroup.Label>

                    <div className="mt-1 bg-white rounded-md -space-y-px">
                      {settings.map((setting, settingIdx) => (
                        <RadioGroup.Option
                          key={setting.name}
                          value={setting}
                          className={({ checked }) =>
                            classNames(
                              settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                              settingIdx === settings.length - 1
                                ? 'rounded-bl-md rounded-br-md'
                                : '',
                              checked ? 'z-10' : '',
                              'relative py-2 flex cursor-pointer focus:outline-none',
                            )
                          }
                        >
                          {({ active, checked }) => (
                            <>
                              <div>
                                <span
                                  className={classNames(
                                    checked
                                      ? 'bg-purple-500 border-transparent'
                                      : 'bg-white border-gray-300',
                                    active ? 'ring-2 ring-offset-2 ring-sky-500' : '',
                                    'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center',
                                  )}
                                  aria-hidden="true"
                                >
                                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                                </span>
                              </div>
                              <div className="ml-3 flex flex-col">
                                <RadioGroup.Label
                                  as="span"
                                  className={classNames(
                                    checked ? 'text-sky-900' : 'text-gray-900',
                                    'block text-sm font-medium',
                                  )}
                                >
                                  {setting.name}
                                </RadioGroup.Label>
                                <RadioGroup.Description
                                  as="span"
                                  className={classNames(
                                    checked ? 'text-sky-700' : 'text-gray-500',
                                    'block text-sm',
                                  )}
                                >
                                  {setting.description}
                                </RadioGroup.Description>
                              </div>
                            </>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup> */}

                  <span className="text-sm font-semibold">Organzation Markup</span>
                  <OrganizationModalPriceTierRow
                    title={`Under $${constants.ORG_TIERS.tier1}`}
                    onChange={(val) => updateTier(val, 1)}
                    value={toInt(tier1)}
                  />
                  <OrganizationModalPriceTierRow
                    title={`$${constants.ORG_TIERS.tier1}-$${constants.ORG_TIERS.tier2}`}
                    onChange={(val) => updateTier(val, 2)}
                    value={toInt(tier2)}
                  />
                  <OrganizationModalPriceTierRow
                    title={`$${constants.ORG_TIERS.tier2}-$${constants.ORG_TIERS.tier3}`}
                    onChange={(val) => updateTier(val, 3)}
                    value={toInt(tier3)}
                  />
                  <OrganizationModalPriceTierRow
                    title={`$${constants.ORG_TIERS.tier3}-$${constants.ORG_TIERS.tier4}`}
                    onChange={(val) => updateTier(val, 4)}
                    value={toInt(tier4)}
                  />
                  <OrganizationModalPriceTierRow
                    title={`Over $${constants.ORG_TIERS.tier4}`}
                    onChange={(val) => updateTier(val, 5)}
                    value={toInt(tier5)}
                  />

                  <div className="flex">
                    <ButtonSquare
                      type="button"
                      className="mr-2 h-9 whitespace-nowrap border-purple-500 py-1 text-sm hover:bg-gray-100"
                      text="Back"
                      disabled={loading}
                      onClick={onClose}
                      outline={true}
                      textColor="purple"
                    />
                    <ButtonSquare
                      type="submit"
                      form="org_submit"
                      className="mr-2 h-9 whitespace-nowrap border-purple-500 py-1 text-sm"
                      text={editMode ? 'Update' : 'Save'}
                      disabled={loading}
                      onClick={handleSubmit}
                    />
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
          <ProductAccessModal
            show={showAccessModal}
            orgName={org?.name}
            onClose={() => setShowAccessModal(false)}
            addProducts={handleSetProducts}
          />
        </Dialog>
      </Transition.Root>
    </>
  )
}
