import { Storage } from 'aws-amplify'
import { useState } from 'react'
import { useAlert } from 'react-alert'
import { classNames, resizeFile } from '../../utils/helpers'

export const ImageUploadButton = ({
  onUploadComplete,
  folder = 'backgrounds/',
  text = 'Upload File',
  className,
  color,
  small,
  outline,
  textColor,
  resizeOptions = null,
  ...rest
}) => {
  const alert = useAlert()

  const [loading, setLoading] = useState(false)

  const _color = color || 'bg-purple'
  const _textColor = textColor || 'text-white'

  const onChange = async (file) => {
    setLoading(true)
    try {
      let resizedFile = file
      if (resizeOptions) {
        resizedFile = await resizeFile(file, resizeOptions)
      }
      const name = folder + file.name.replace(' ', '-').toLowerCase()
      const { key } = await Storage.put(name, resizedFile, {
        contentType: 'image/png',
      })
      onUploadComplete({ key, name: file.name })
      alert.success('Image uploaded.')
      setLoading(false)
    } catch (error) {
      alert.error(error)
      setLoading(false)
    }
  }

  return (
    <>
      <label htmlFor={'upload-button'}>
        <div
          className={classNames(
            'flex cursor-pointer items-center justify-center rounded shadow-sm',
            small ? 'px-2 py-1 text-sm' : 'h-9 px-4 py-1 text-base',
            outline
              ? `border ${_textColor} outline-none focus:outline-none`
              : `border border-transparent ${_textColor} ${_color}-500 hover:${_color}-700 transition-colors outline-none duration-200 focus:outline-none`,
            className,
          )}
        >
          {loading ? <span>Uploading...</span> : <span>{text}</span>}
        </div>
      </label>
      <input
        id="upload-button"
        className="hidden shadow-sm"
        type="file"
        accept="image/*"
        onChange={(e) => onChange(e.target.files[0])}
        {...rest}
      />
    </>
  )
}
