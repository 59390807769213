import { useEffect, useRef, useState } from 'react'
import { useAlert } from 'react-alert'
import Masonry from 'react-masonry-css'

import { api } from '../../services/api'
import { useInit } from '../../utils/hooks'

import { SearchInput } from '../inputs/SearchInput'

import './masonry.css'

export default function BackgroundImageModalUnsplashTab({ onSelect }) {
  const alert = useAlert()

  const [images, setImages] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  const formRef = useRef(null)

  const fetchGallery = async (e) => {
    e?.preventDefault()
    try {
      const { data } = await api.Unsplash.search({ query: searchTerm, page: 1, per_page: 40 })
      setImages(data.results)
    } catch (error) {
      alert.error(error)
    }
  }
  // console.log('IMAGES', images?.[0])

  useInit(fetchGallery)

  async function handleSelect(image) {
    // make an authenticated call to image.links.download_location for usage attribution
    try {
      api.Unsplash.callImageAuthUrl(image.links.download_location)
    } catch (error) {
      console.log(error)
    } finally {
      onSelect({ url: image.urls.regular })
    }
  }

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        formRef.current?.click()
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [])

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    if (bottom) {
      console.log('bottom')
    }
  }

  return (
    <form className="flex-flex-col" onSubmit={fetchGallery}>
      <div className="flex justify-between">
        <div className="w-3/4 md:max-w-1/2">
          <SearchInput onChange={setSearchTerm} />
        </div>
      </div>
      <Masonry
        breakpointCols={3}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
        onScroll={handleScroll}
      >
        {images.map((image, idx) => (
          <BackgroundImageItem key={idx} image={image} onSelect={handleSelect} />
        ))}
      </Masonry>
      <button className="hidden" ref={formRef} type="submit"></button>
    </form>
  )
}

const BackgroundImageItem = ({ image, onSelect }) => {
  function handleSelect(img) {
    onSelect(img)
  }

  return (
    <div key={image.id} className="relative mb-6 w-full bg-white">
      <img
        onClick={() => handleSelect(image)}
        className="cursor-pointer object-fill"
        alt={image.alt_description}
        src={image.urls.regular}
        // src="https://source.unsplash.com/collection/1163637/580x480"
      />
      <span
        aria-hidden="true"
        className="absolute inset-x-0 bottom-0 h-8 bg-gradient-to-t from-gray-200 opacity-90"
      />
      <div className="absolute bottom-1 left-1 flex">
        <a
          target="_blank"
          rel="noreferrer"
          href={`${image.user.links.html}?utm_source=Passport&utm_medium=referral`}
          className="break-words text-xxs"
        >
          {image.user.username}
        </a>
        <span className="break-words text-xxs">&nbsp;via&nbsp;</span>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://unsplash.com?utm_source=Passport&utm_medium=referral"
          className="break-words text-xxs"
        >
          Unsplash
        </a>
      </div>
    </div>
  )
}
