import { store } from '../store'

import {
  startNewDeck as snd,
  addSlide as add,
  updateCurrentSlide as ucs,
  setCurrentSlideIndex as scsi,
  addProductToSlide as apts,
  updateCurrentDeck as ucd,
  clear,
  fetchDeck as fd,
  createDeck as cd,
  updateDeck as ud,
  setAlternateImage as sai,
  updateCurrentSlideType as ucst,
  duplicateSlide as ds,
  deleteSlide as del,
  bulkCreateSlides as bcs,
  updateDeckModifier as udm,
  updateDeckLayout as udl,
  updateDeckSlidesDefaultImages as udsdi,
  addMasterToSlide as amts,
  addExistingSlide as aes,
  createSlideFromProduct as csfp,
} from './decksSlice'

export const startNewDeck = () => store.dispatch(snd())

export const addSlide = () => store.dispatch(add())

export const updateCurrentSlide = (data) => store.dispatch(ucs(data))

export const setCurrentSlideIndex = (index) => store.dispatch(scsi(index))

export const addProductToSlide = (data) => store.dispatch(apts(data))

export const updateCurrentDeck = (data) => store.dispatch(ucd(data))

export const clearDeck = () => store.dispatch(clear())

export const createDeck = (data) => store.dispatch(cd(data)).unwrap()

export const updateDeck = (data) => store.dispatch(ud(data)).unwrap()

export const fetchDeck = (data) => store.dispatch(fd(data))

export const setAlternateImage = (data) => store.dispatch(sai(data))

export const updateCurrentSlideType = (data) => store.dispatch(ucst(data))

export const duplicateSlide = () => store.dispatch(ds())

export const deleteSlide = () => store.dispatch(del())

export const bulkCreateSlides = (data) => store.dispatch(bcs(data))

export const updateDeckModifier = (data) => store.dispatch(udm(data))

export const updateDeckLayout = (data) => store.dispatch(udl(data))

export const updateDeckSlidesDefaultImages = (data) => store.dispatch(udsdi(data))

export const addMasterToSlide = (data) => store.dispatch(amts(data))

export const addExistingSlide = (slide) => store.dispatch(aes(slide))

export const createSlideFromProduct = (product) => store.dispatch(csfp(product))
