import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import { USER_LOGOUT } from './types'

import { signOut } from '../../services/auth'
import { IUser } from '../../types/modelTypes'

// type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>
// type AtLeastOne<T, U = {[K in keyof T]: Pick<T, K> }> = Partial<T> & U[keyof U]
// type UserWithId = {
//   id: number
// }

export const logOut = createAsyncThunk(USER_LOGOUT, async (_, thunkAPI) => {
  try {
    await signOut()
    window.localStorage.clear()
    return
  } catch (error) {
    if (axios.isAxiosError(error)) {
      thunkAPI.rejectWithValue(error.message)
    }
  }
})

type UserState = IUser | null
const initialState = null as UserState

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      return action.payload
    },
    updateUser: (state, action: PayloadAction<IUser>) => {
      return { ...state, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logOut.fulfilled, (state, action) => {
      return initialState
    })
  },
})

// Action creators are generated for each case reducer function
export const { setUser, updateUser } = userSlice.actions

export default userSlice.reducer
