import { FolderAddIcon } from '@heroicons/react/outline'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
// import { useHistory } from 'react-router'
import { useAlert } from 'react-alert'

import { api } from '../../services/api'
import { setCrumbs } from '../../data/globals'

import AddFolderModal from '../modals/AddFolderModal'
import Breadcrumbs from './Breadcrumbs'
import DirectoryList from './DirectoryList'
import { selectUser } from '../../data/user/selectors'
// import Tree from './Tree'

// const structure2 = [
//   {
//     type: 'folder',
//     name: 'src',
//     childrens: [
//       {
//         type: 'folder',
//         name: 'Components',
//         childrens: [
//           { type: 'file', name: 'Modal.js' },
//           { type: 'file', name: 'Modal.css' },
//         ],
//       },
//       { type: 'file', name: 'index.js' },
//       { type: 'file', name: 'index.html' },
//     ],
//   },
//   { type: 'file', name: 'package.json' },
// ]

const initialCrumbs = [
  {
    name: 'Users',
    level: 'users',
    directoryId: null,
    userId: null,
    current: true,
  },
]

export const directoryLevels = {
  user: {
    child: 'client',
  },
  client: {
    child: 'sub_1',
  },
  sub_1: {
    child: 'sub_2',
  },
}

export function Directory({ onDirChange = () => {} }) {
  // const history = useHistory()
  const alert = useAlert()
  // const currentDeck = useSelector((state) => state.decks)
  const crumbs = useSelector((state) => state.globals.crumbs)
  const user = useSelector(selectUser)

  const [structure, setStructure] = useState([])
  const [isFolderModalVisible, setIsFolderModalVisible] = useState(false)
  // const [isFileModalVisible, setIsFileModalVisible] = useState(false)

  const currentCrumb = crumbs[crumbs.length - 1]
  const disableFolderAdd = currentCrumb?.level === 'users' || currentCrumb?.level === 'sub_2'

  const handleCrumbChange = async (crumb) => {
    onDirChange(currentCrumb)
    // if (currentDeck.directory?.id) {
    //   // if we are loading a directory from a continuity, we need to get the children of that directory
    //   // used when opening a continuity to edit
    //   getDirectoryChildren(currentDeck.directory.id)
    // } else
    if (currentCrumb?.directoryId) {
      // if directoryId then it is not a top level directory, so fetch its children
      await getDirectoryChildren(currentCrumb.directoryId)
    } else {
      setCrumbs(initialCrumbs)
      // if (user) {
      //   getDirectoryForUser()
      // } else {
      getAllTopLevelNodes()
      // }
    }
  }

  // const getDirectoryForUser = async () => {
  //   if (!user) return
  //   try {
  //     const { data } = await api.User.getDirectory(user.id)
  //     setStructure([data])
  //   } catch (error) {
  //     alert.error(error.message)
  //   }
  // }

  useEffect(() => {
    handleCrumbChange(currentCrumb)
  }, [currentCrumb])

  async function getAllTopLevelNodes() {
    try {
      const nodes = await api.Directory.getAllTopLevel({ withMaster: user.masterAccess })
      setStructure(nodes)
    } catch (error) {
      alert.error(error.message)
    }
  }

  async function getDirectoryChildren(dirId) {
    try {
      const data = await api.Directory.getWithChildren(dirId)
      const { children, masters, continuities } = data
      setStructure([...children, ...masters, ...continuities])
      return {
        name: data.name,
        userId: data.userId,
        directoryId: data.id,
        current: true,
        level: data.level,
      }
    } catch (error) {
      console.log('ERROR', error)
    }
  }

  function handleCrumbClick(crumb) {
    if (!crumb && user) {
      // this occurs if the home icon is clicked in profile view
      return
    } else if ((crumb === null || crumb.directoryId === null) && !user) {
      setCrumbs(initialCrumbs)
      getAllTopLevelNodes()
    } else {
      const desiredCrumbIndex = crumbs.findIndex((c) => c.name === crumb.name)
      const newCrumbs = crumbs.slice(0, desiredCrumbIndex + 1)
      setCrumbs(newCrumbs)
    }
  }

  async function handleItemClick(item) {
    if (item.type === 'folder') {
      const newCrumbs = await getDirectoryChildren(item.id)
      setCrumbs([...crumbs, newCrumbs])
    } else {
      // TODO for now, you can only click on folders
      // try {
      //   if (currentDeck.filename) {
      //     // then we are accessing this from the builder and any file clicked on should replace the
      //     // current decks filename for saving
      //     updateCurrentDeck({
      //       filename: item.filename,
      //     })
      //   } else {
      //     // otherwise fetch load that deck into state
      //     const { data } = await api.ContinuityMaster.getById(item.id, true)
      //     updateCurrentSlide(data.continuities[0])
      //     updateCurrentDeck(data)
      //     history.replace('/builder')
      // }
      // } catch (error) {
      //   console.log('ERROR', error)
      // }
    }
  }

  async function handleAddFolder(name) {
    const parentDir = currentCrumb
    try {
      const dir = await api.Directory.create({
        userId: parentDir.userId,
        name,
        parentId: parentDir.directoryId,
        type: 'folder',
        level: directoryLevels[parentDir.level].child,
      })
      setStructure([...structure, dir])
      setIsFolderModalVisible(false)
    } catch (error) {
      console.error(error)
    }
  }

  // async function handleAddFile() {
  //   const parentDir = currentCrumb
  //   try {
  //     const dir = await api.Directory.create({
  //       userId: user.username,
  //       name,
  //       parentId: parentDir.directoryId,
  //       type: 'folder',
  //     })
  //     setStructure([...structure, dir])
  //     setIsFolderModalVisible(false)
  //   } catch (error) {
  //     console.error(error)
  //   }
  // }

  return (
    <div>
      <div className=" mb-4 flex items-center justify-between">
        <Breadcrumbs pages={crumbs} onClick={handleCrumbClick} />
        <div className="flex items-center">
          {!disableFolderAdd && (
            <button className="focus:outline-none" onClick={() => setIsFolderModalVisible(true)}>
              <FolderAddIcon className="mr-2 h-6 w-6 text-gray-500" />
            </button>
          )}
          {/* <button className="focus:outline-none" onClick={() => setIsFileModalVisible(true)}>
            <DocumentAddIcon className="h-6 w-6 text-gray-500" />
          </button> */}
        </div>
      </div>
      <DirectoryList items={structure} onClick={handleItemClick} />
      <div className="h-2"></div>
      <AddFolderModal
        show={isFolderModalVisible}
        title={`Add folder to ${crumbs[crumbs.length - 1]?.name}`}
        onAccept={handleAddFolder}
        onClose={() => setIsFolderModalVisible(false)}
      />

      {/* <Tree data={structure2} /> */}

      {/* <div>
        <Tree>
          <Tree.Folder name="src">
            <Tree.Folder name="Components">
              <Tree.File name="Modal.js" />
              <Tree.File name="Modal.css" />
            </Tree.Folder>
            <Tree.File name="index.js" />
            <Tree.File name="index.html" />
          </Tree.Folder>
          <Tree.File name="package.json" />
        </Tree>
      </div> */}
    </div>
  )
}
