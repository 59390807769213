import { useEffect, useState } from 'react'
import { XIcon } from '@heroicons/react/solid'

import constants from '../../utils/constants'
import { MenuGeneric } from '../MenuGeneric'
import { setCurrentProductSort, setFilters } from '../../data/globals'

export default function ResultsAndSortSection({
  data,
  userRole,
  filters,
  sort,
  onSetCurrentSort,
  onSetPage,
  sortOptions,
  hideSort = false,
}) {
  const [options] = useState(sortOptions?.[userRole ?? 'user'])

  useEffect(() => {
    setCurrentProductSort(options[0])
  }, [options])

  function handleRemoveFilter(filterKey) {
    const newFilters = filters.filter((f) => f.key !== filterKey)
    setFilters(newFilters)
  }

  return (
    <div
      style={{ minHeight: '32px' }}
      className="sticky top-0 z-10 flex w-full justify-between border-b border-gray-300 bg-white pb-1 pt-2"
    >
      <div className="flex flex-wrap">
        <p className="w-38 mt-2 inline-flex pr-6 text-xs">
          Results:{' '}
          {data?.meta?.total_results < 100 ? data?.meta?.total_results : data?.meta?.limit ?? 0} of{' '}
          {Math.ceil(data?.meta?.total_results ?? 0)}
        </p>
        {userRole === constants.ROLES.USER || true
          ? filters.map((f, idx) => {
              return (
                <button
                  onClick={() => handleRemoveFilter(f.key)}
                  key={idx}
                  className="transition-colors mb-1 mr-2 flex items-center rounded bg-gray-100 px-2 py-1 text-xs text-gray-600 duration-200 hover:bg-gray-200"
                >
                  {f.operator?.value === 'gte' ? '> ' : f.operator?.value === 'lte' ? '< ' : ''}
                  {f.attr?.[0]?.label} <XIcon className="ml-1 h-3 w-3" />
                </button>
              )
            })
          : null}
      </div>
      {!hideSort ? (
        <MenuGeneric
          options={options}
          value={sort}
          onChange={(val) => {
            onSetCurrentSort(val)
            onSetPage(0)
          }}
        />
      ) : null}
    </div>
  )
}
