// @ts-nocheck
// TODO finish typing this out
import {
  IDirectory,
  IUser,
  IProduct,
  IContinuity,
  IList,
  IContinuityMaster,
  IClient,
  IGalleryItem,
  IOrganization,
} from '../types/modelTypes'
import { requests } from './requests'
import {
  IContinuityMasterCreatePayload,
  IContinuityMasterUpdatePayload,
  IDirectorySearchMastersPayload,
  IListGetForUserPayload,
  IUserCreatePayload,
  IUserGetAllPayload,
  IUserGetByIDPayload,
} from './types'

export const api = {
  User: {
    getAll: (queryParams: IUserGetAllPayload) => {
      const {
        withOrganizations = false,
        withFavorites = false,
        role = '',
        searchTerm = '',
        status = '',
        organizationId = '',
      } = queryParams
      return requests.get<IUser[]>(
        `/api/v1/user?with_favorites=${withFavorites}&with_organizations=${withOrganizations}&role=${role}&search=${searchTerm}&status=${status}&organization_id=${organizationId}`,
      )
    },
    getByID: (queryParams: IUserGetByIDPayload) => {
      const { id, withFavorites = '' } = queryParams
      return requests.get<IUser>(`/api/v1/user/${id}?with_favorites=${withFavorites}`)
    },
    // the uuid is the cognito identifier
    getByUUID: (uuid: string) => {
      return requests.get<IUser>(`/api/v1/user/uuid/${uuid}`)
    },
    create: (user: IUserCreatePayload) =>
      requests.post<IUserCreatePayload, IUser>(`/api/v1/user`, user),
    update: (id: number, newData: Partial<IUser>) =>
      requests.put<Partial<IUser>, IUser>(`/api/v1/user/${id}`, newData),
    getFavorites: (userId: number) => requests.get<IProduct[]>(`/api/v1/user/${userId}/favorites`),
    // addFavorites: (userId: number, productIds: number[]) =>
    //   requests.post(`/api/v1/user/${userId}/favorites`, { productIds }),
    removeFavorites: (userId: number, productIds: number[]) =>
      requests.put<{ productIds: number[] }, number[]>(`/api/v1/user/${userId}/favorites`, {
        productIds,
      }),
    getDirectory: (userId: number) => requests.get<IDirectory>(`/api/v1/user/${userId}/directory`),
    softDelete: (userId: number) =>
      requests.post<any, IUser>(`/api/v1/user/soft_delete/${userId}`, {}),
    delete: (userId: number) => requests.delete<IUser>(`/api/v1/user/${userId}`),
    addToOrganization: (userId: number, organizationId: number) =>
      requests.post<any, IUser>(`/api/v1/user/${userId}/organization/${organizationId}`, {}),
  },
  Continuity: {
    getAll: () => requests.get<IContinuity[]>(`/api/v1/continuity`),
    getById: (id: number, with_master = false, with_products = false) =>
      requests.get<IContinuity>(
        `/api/v1/continuity/${id}?with_master=${with_master}&with_products=${with_products}`,
      ),
    getAllForUser: (userId: number) =>
      requests.get<IContinuity[]>(`/api/v1/continuity/user/${userId}`),
    getAllMasters: () => requests.get(`/api/v1/continuity/masters`),
    create: (cont: IContinuity) => requests.post(`/api/v1/continuity`, cont),
    update: (uuid: string, cont: Partial<IContinuity>) =>
      requests.put(`/api/v1/continuity/${uuid}`, cont),
    search: (query: string) =>
      requests.get<IContinuity>(`/api/v1/continuity/search?query=${query}`),
  },
  ContinuityMaster: {
    getAll: () => requests.get(`/api/v1/continuity_master`),
    getById: (id: string, with_products: boolean) =>
      requests.get<IContinuityMaster>(
        `/api/v1/continuity_master/${id}?with_products=${with_products}`,
      ),
    getAllForUser: (userId) => requests.get(`/api/v1/continuity_master/user/${userId}`),
    create: (data: IContinuityMasterCreatePayload) =>
      requests.post<IContinuityMasterCreatePayload, IContinuityMaster>(
        `/api/v1/continuity_master`,
        data,
      ),
    update: (id: number, data: IContinuityMasterUpdatePayload) =>
      requests.put<IContinuityMasterUpdatePayload, IContinuityMaster>(
        `/api/v1/continuity_master/${id}`,
        data,
      ),
    delete: (id) => requests.delete(`/api/v1/continuity_master/${id}`),
  },
  Client: {
    getAll: () => requests.get<IClient[]>(`/api/v1/client`),
    getById: (id) => requests.get<IClient>(`/api/v1/client/${id}`),
    getAllForClient: (clientId) => requests.get(`/api/v1/continuity/master/${clientId}`),
    create: (data: { name: string }) => requests.post(`/api/v1/client`, data),
  },
  Favorite: {
    get: (userId) => requests.get(`/api/v1/favorite/${userId}`),
    upsert: (data) => requests.post(`/api/v1/favorite`, data),
    addBulk: (data) => requests.post(`/api/v1/favorite/bulk`, data),
  },
  Salsify: {
    products: {
      get: (queryParams) => {
        const { term, page, per_page, filter, sort } = queryParams
        return requests.get(
          `/api/v1/salsify/products?term=${term}&page=${page}&per_page=${per_page}&filter=${filter}&sort=${sort}`,
        )
      },
      getBulk: (ids) => requests.post(`/api/v1/salsify/products/bulk`, ids),
      getById: (id) => requests.get(`/api/v1/salsify/products/${id}`),
      getCategories: () => requests.get(`/api/v1/salsify/products/categories`),
      getSubCategories: () => requests.get(`/api/v1/salsify/products/subcategories`),
      getBrands: ({ page, per_page }) =>
        requests.get(`/api/v1/salsify/products/brands?page=${page}&per_page=${per_page}`),
      getColors: () => requests.get(`/api/v1/salsify/products/colors`),
      getVendors: () => requests.get(`/api/v1/salsify/vendors`),
      getTags: () => requests.get(`/api/v1/salsify/tags`),
      search: (queryParams) => {
        const { term, page, per_page } = queryParams
        requests.get(
          `/api/v1/salsify/products/search/?term=${term}&page=${page}&per_page=${per_page}`,
        )
      },
      post: (url, body, headers = {}) => requests.post(url, body, headers),
      put: (url, body) => requests.put(url, body),
      patch: (url, body) => requests.patch(url, body),
      delete: (url) => requests.delete(url),
    },
  },
  Products: {
    getAll: (queryParams) => {
      const {
        search = '',
        limit = 30,
        offset = 0,
        order = '',
        order_by = '',
        filter = '',
        exclusives_only = '',
        org_markup,
        org_id = '',
      } = queryParams
      return requests.get<IProduct[]>(
        `/api/v1/product?limit=${limit}&offset=${offset}&order=${order}&order_by=${order_by}&tier1=${
          org_markup.tier1
        }&tier2=${org_markup.tier2}&tier3=${org_markup.tier3}&tier4=${org_markup.tier4}&tier5=${
          org_markup.tier5
        }&org_id=${org_id}&filter=${encodeURIComponent(
          filter,
        )}&search=${search}&exclusives_only=${exclusives_only}`,
      )
    },
    getById: (id: number) => requests.get(`/api/v1/product/${id}`),
    getByIdWithChildren: (id) => requests.get(`/api/v1/product/${id}/children`),
    getHomepageAssets: () => requests.get<Record<number, IProduct>>(`/api/v1/product/homepage`),
    getByOrganization: (orgId) => requests.get(`/api/v1/product/organization/${orgId}`),
  },
  ProductAttributes: {
    getAll: (grouped = false) => requests.get(`/api/v1/product_attributes?grouped=${grouped}`),
  },
  Directory: {
    getAllTopLevel: ({ withMaster }) => requests.get(`/api/v1/directory?with_master=${withMaster}`),
    getWithChildren: (directoryId) => requests.get(`/api/v1/directory/${directoryId}/children`),
    getForUser: (userId) => requests.get(`/api/v1/directory/user/${userId}`),
    getMasters: () => requests.get(`/api/v1/directory/masters`),
    create: (data) => requests.post(`/api/v1/directory`, data),
    addContinuityMaster: (data) => requests.post(`/api/v1/directory/continuity/`, data),
    delete: (id) => requests.delete(`/api/v1/directory/${id}`),
    searchMasters: (queryParams: IDirectorySearchMastersPayload) => {
      const {
        search = '',
        limit = 30,
        offset = 0,
        order = '',
        order_by = '',
        filter = '',
        org_id = '',
      } = queryParams
      return requests.get<IContinuity[]>(
        `/api/v1/directory/masters/search?query=${search}&offset=${offset}&order=${order}&order_by=${order_by}&filter=${encodeURIComponent(
          filter,
        )}&limit=${limit}&org_id=${org_id}`,
      )
    },
  },
  List: {
    getAll: () => requests.get(`/api/v1/list`),
    getById: (id) => requests.get(`/api/v1/list/${id}`),
    getForUser: (queryParams: IListGetForUserPayload) => {
      const { userId = null, search = '', order = '', order_by = '' } = queryParams
      return requests.get<IList[]>(
        `/api/v1/list/user/${userId}?search=${search}&order=${order}&order_by=${order_by}`,
      )
    },
    // { name, userId }
    create: (data) => requests.post(`/api/v1/list`, data),
    update: (id, data) => requests.put(`/api/v1/list/${id}`, data),
    addToMyFavorites: (userId, productIds) =>
      requests.post(`/api/v1/list/my_favorites/${userId}`, { productIds }),
    removeFromMyFavorites: (userId, productIds) =>
      requests.put(`/api/v1/list/my_favorites/${userId}`, { productIds }),
    addProducts: (listId, productIds) =>
      requests.post(`/api/v1/list/${listId}/products`, { productIds }),
    removeProduct: (listId, productId) =>
      requests.delete(`/api/v1/list/${listId}/product/${productId}`),
    removeProducts: (listId, productIds) =>
      requests.patch(`/api/v1/list/${listId}/products`, { productIds }),
    delete: (id) => requests.delete(`/api/v1/list/${id}`),
  },
  Gallery: {
    getAll: (queryParams) => {
      const { searchTerm = '' } = queryParams
      return requests.get<IGalleryItem[]>(`/api/v1/gallery?search=${searchTerm}`)
    },
    create: (data) => requests.post(`/api/v1/gallery`, data),
    delete: (data) => requests.post(`/api/v1/gallery/delete`, data),
  },
  Organizations: {
    getAll: (with_members = false, searchTerm = '') =>
      requests.get<IOrganization[]>(
        `/api/v1/organization?with_members=${with_members}&search=${searchTerm}`,
      ),
    getById: (id) => requests.get<IOrganization>(`/api/v1/organization/${id}`),
    create: (data: Partial<IOrganization>) => requests.post(`/api/v1/organization`, data),
    bulkCreate: (entries) => requests.post(`/api/v1/organization/bulk`, { entries }),
    update: (id, data) => requests.put(`/api/v1/organization/${id}`, data),
    delete: (id) => requests.delete(`/api/v1/organization/${id}`),
    updateProducts: (id, data) => requests.post(`/api/v1/organization/add-products/${id}`, data),
  },
  Unsplash: {
    // get: (queryParams) => {
    //   const { term, page, per_page, filter, sort, showInactive } = queryParams
    //   return requests.get(
    //     `/api/v1/salsify/products?term=${term}&page=${page}&per_page=${per_page}&filter=${filter}&sort=${sort}&show_inactive=${showInactive}`,
    //   )
    // },
    callImageAuthUrl: (authUrl) => requests.post(`/api/v1/unsplash/auth/image`, { url: authUrl }),
    search: ({ query, page, per_page }) => {
      query = query ? query : 'backgrounds'
      return requests.get(
        `/api/v1/unsplash/search/photos?query=${query}&page=${page}&per_page=${per_page}`,
      )
    },
  },
  Quote: {
    getQuoteNumber: () => requests.get<{ number: string }>(`/api/v1/quote`),
    incrementQuoteNumber: (id: number) =>
      requests.put<_, { data: { number: string } }>(`/api/v1/quote/${id}`),
  },
}
