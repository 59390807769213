import { ChevronRightIcon, FolderIcon, DocumentIcon } from '@heroicons/react/solid'

export default function DirectoryList({ items = [], onClick = () => {} }) {
  return (
    <div className="w-full overflow-hidden bg-white shadow sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {items
          .sort((a, b) => (a.type === 'folder' ? -1 : 1))
          .map((item, idx) => (
            <li key={idx} className="w-full">
              <button
                onClick={() => onClick(item)}
                className="flex w-full outline-none hover:bg-gray-50 focus:outline-none"
              >
                <div className="flex w-full items-center justify-between px-4 py-4 sm:px-6">
                  <div className="flex min-w-0 flex-1 items-center">
                    <div className="flex-shrink-0">
                      {item.type === 'folder' ? (
                        <FolderIcon className="h-8 w-8 text-gray-500" />
                      ) : (
                        <DocumentIcon className="h-8 w-8 text-gray-500" />
                      )}
                    </div>
                    <div className="min-w-0 px-4 md:grid md:grid-cols-2 md:gap-4">
                      <p className="truncate text-sm font-medium text-black">
                        {item.type === 'folder' ? item.name : item.filename}
                      </p>
                    </div>
                  </div>
                  <div className="ml-auto">
                    {item.type === 'folder' && (
                      <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    )}
                  </div>
                </div>
              </button>
            </li>
          ))}
      </ul>
    </div>
  )
}
