import { createSlice, createAsyncThunk, nanoid, PayloadAction } from '@reduxjs/toolkit'
import { Storage } from 'aws-amplify'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'

import { api } from '../../services/api'
import constants from '../../utils/constants'
import {
  CREATE_DECK,
  FETCH_DECK,
  UPDATE_DECK,
  IDeckSliceInitialState,
  IFetchDeckPayload,
  ICreateDeckPayload,
  IUpdateDeckPayload,
} from 'data/decks/types'
import { ContinuityType, IContinuity, IContinuityMaster, IUser } from 'types/modelTypes'

export const priceAdjustOptions = [
  { id: 0, name: 'none', value: 1 },
  { id: 1, name: '20%', value: 0.8 },
  { id: 2, name: '25%', value: 0.75 },
  { id: 3, name: '30%', value: 0.7 },
  { id: 4, name: '35%', value: 0.65 },
  { id: 5, name: 'Custom', value: 'custom' },
]

const uploadFiles = async (deck: IContinuityMaster, user: IUser, filename: string) => {
  return await Promise.all(
    deck.continuities.map(async (c, idx) => {
      // if a thumbnail wasn't modified, it will be https url
      // otherwise will be objectURL
      if (!c.thumbnail) {
        throw new Error('Thumbnail is missing from continuity.')
      }
      if (c.thumbnail?.startsWith('https')) {
        return c
      }
      const blobData = await fetch(c.thumbnail).then((res) => res.blob())
      const filePath = `${user.name.replace(' ', '-')}-${user.id}/${filename}_${idx}`
      const { key } = await Storage.put(filePath, blobData, {
        contentType: 'image/png',
      })
      return {
        ...c,
        thumbnail: `${process.env.REACT_APP_BUCKET_BASE_URL}/public/${key}`,
      }
    }),
  )
}

export const fetchDeck = createAsyncThunk(FETCH_DECK, async (data: IFetchDeckPayload, thunkAPI) => {
  const { id, withProducts = false } = data
  try {
    const { data } = await api.ContinuityMaster.getById(String(id), withProducts)
    return data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkAPI.rejectWithValue(error.message)
    } else {
      return thunkAPI.rejectWithValue(error)
    }
  }
})

export const createDeck = createAsyncThunk(
  CREATE_DECK,
  async (payload: ICreateDeckPayload, thunkAPI) => {
    const { newDeck, filename, dirId, user } = payload
    try {
      const contsWithImages = await uploadFiles(newDeck, user, filename)
      newDeck.continuities = contsWithImages

      const { data } = await api.ContinuityMaster.create({
        ...newDeck,
        username: user.name,
        dirId,
        filename,
      })
      return data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkAPI.rejectWithValue(error.message)
      } else {
        return thunkAPI.rejectWithValue(error)
      }
    }
  },
)

export const updateDeck = createAsyncThunk(
  UPDATE_DECK,
  async (payload: IUpdateDeckPayload, thunkAPI) => {
    try {
      const { id, newDeck, filename, dirId, user } = payload
      const contsWithImages = await uploadFiles(newDeck, user, filename)
      newDeck.continuities = contsWithImages
      const { data } = await api.ContinuityMaster.update(id, {
        ...newDeck,
        username: user.name,
        dirId,
        filename,
      })
      return data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkAPI.rejectWithValue(error.message)
      } else {
        return thunkAPI.rejectWithValue(error)
      }
    }
  },
)

const defaultSlide: IContinuity = {
  id: null,
  uuid: uuidv4(),
  userId: '',
  position: 0,
  title: '',
  name: '',
  price: 0, // this is only used when exporting to powerpoint. Otherwise we always compute price from modifier and salisfy product data
  priceModifier: 0.7, //priceAdjustOptions?.find((opt) => opt.name === '30%').value,
  priceModified: false,
  layoutModified: false,
  products: [
    // a temp id used only for sorting products in the builder
    { id: uuidv4() },
    { id: uuidv4() },
    { id: uuidv4() },
    { id: uuidv4() },
    { id: uuidv4() },
  ] as any,
  altImages: {}, // key:value pair of product id and alt image index
  type: ContinuityType.single,
  thumbnail: '',
  backgroundImage:
    'https://gps-portal-background-images75804-dev.s3.us-west-2.amazonaws.com/public/backgrounds/gps_purple-square.jpg',
  overlayImage: '',
  layout: 'borderless',
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
}

const titleSlide: IContinuity = {
  id: null,
  uuid: uuidv4(),
  userId: '',
  position: 0,
  title: '',
  name: '',
  price: 0, // this is only used when exporting to powerpoint. Otherwise we always compute price from modifier and salisfy product data
  priceModifier: 0.7, //priceAdjustOptions?.find((opt) => opt.name === '30%').value,
  priceModified: false,
  layoutModified: false,
  products: [],
  altImages: {}, // key:value pair of product id and alt image index
  type: ContinuityType.title,
  thumbnail: 'https://gps-solutions-assets.s3.us-west-2.amazonaws.com/gps_title_slide_black.png',
  backgroundImage: '',
  overlayImage: '',
  layout: '',
  createdAt: new Date(),
  updatedAt: new Date(),
}

const initialState: IDeckSliceInitialState = {
  title: '',
  recipient: '',
  continuities: [],
  budget: 0,
  id: null,
  type: '',
  userId: null,
  filename: '',
  clientId: null,
  directoryId: null,
  directory: null,
  slideIndex: 0,
  defaultModifier: priceAdjustOptions.find((opt) => opt.name === '30%')!.value,
  defaultLayout: 'borderless',
  defaultBackgroundImage:
    'https://gps-portal-background-images75804-dev.s3.us-west-2.amazonaws.com/public/backgrounds/gps_purple-square.jpg',
}

export const decksSlice = createSlice({
  name: 'decks',
  initialState,
  reducers: {
    startNewDeck: {
      // TODO switch to just use nanoid
      prepare: () => {
        const id = uuidv4()
        return { payload: id }
      },
      reducer: (state, action: PayloadAction<string>) => {
        return {
          ...initialState,
          continuities: [{ ...titleSlide, id: action.payload }],
        }
      },
    },
    updateCurrentDeck: (state, action: PayloadAction<Partial<IDeckSliceInitialState>>) => {
      // Do not use to update slides
      return {
        ...state,
        ...action.payload,
      }
    },
    addSlide: (state) => {
      const newSlide = {
        ...defaultSlide,
        id: nanoid(),
        priceModifier: state.defaultModifier,
        layout: state.defaultLayout,
        backgroundImage: state.defaultBackgroundImage
          ? state.defaultBackgroundImage
          : defaultSlide.backgroundImage,
      }
      const newSlides = [...(state.continuities || []), newSlide]
      state.continuities = newSlides
      state.slideIndex = newSlides.length - 1
    },
    addExistingSlide: (state, action) => {
      const slides = state.continuities || []
      const newSlides = [...slides, action.payload]
      if (slides && slides.length === 0) {
        newSlides.unshift(titleSlide)
      }
      state.continuities = newSlides
      state.slideIndex = newSlides.length - 1
    },
    addMasterToSlide: (state, action) => {
      const currentSlideIndex = state.slideIndex
      // make sure that action.payload.products is length 5
      const slide = action.payload
      const prods = [...action.payload.products]
      for (let i = 0; i < 5; i++) {
        if (!prods[i]) {
          prods.push({ id: uuidv4() })
        }
      }
      slide.products = prods
      if (state.continuities) {
        state.continuities[currentSlideIndex] = slide
      }
    },
    updateCurrentSlide: (state, action) => {
      if (state.continuities) {
        state.continuities[state.slideIndex] = {
          ...state.continuities[state.slideIndex],
          ...action.payload,
        }
      }
    },
    updateCurrentSlideType: (state, action) => {
      const { type } = action.payload
      const slide = state.continuities[state.slideIndex]
      slide.type = type
      slide.layoutModified = true
      slide.products = [
        ...slide.products,
        { id: nanoid() },
        { id: nanoid() },
        { id: nanoid() },
        { id: nanoid() },
      ].slice(0, 5) as any
    },
    setCurrentSlideIndex: (state, action) => {
      state.slideIndex = action.payload
    },
    duplicateSlide: (state, action) => {
      // we can't duplicate id's
      const currentSlide = {
        ...state.continuities[state.slideIndex],
        id: nanoid(16),
      }
      // if we duplicate a 4 item slide, then want to change it's layout to 5 item,
      // we need to repopulate empty slide products
      for (let i = 0; i < 5; i++) {
        if (!currentSlide.products[i]) {
          currentSlide.products[i] = { id: nanoid() } as any
        }
      }
      const duplicated = [...state.continuities, currentSlide]
      state.continuities = duplicated
    },
    deleteSlide: (state, action) => {
      const idx = state.slideIndex
      if (idx !== -1) {
        const newConts = state.continuities
        newConts.splice(idx, 1)
        state.continuities = newConts
        state.slideIndex = idx > 0 ? idx - 1 : 0
      }
    },
    createSlideFromProduct: (state, action) => {
      const newSlide = {
        ...defaultSlide,
        products: [
          action.payload,
          { id: nanoid() },
          { id: nanoid() },
          { id: nanoid() },
          { id: nanoid() },
        ],
        id: nanoid(),
      }
      const newSlides = [...(state.continuities || []), newSlide]
      state.continuities = newSlides
      state.slideIndex = newSlides.length - 1
    },
    bulkCreateSlides: (
      state,
      action: PayloadAction<{ products: any[]; continuities: IContinuity[] }>,
    ) => {
      const slidesFromProducts = action.payload.products.map((p) => ({
        ...defaultSlide,
        products: [p, { id: nanoid() }, { id: nanoid() }, { id: nanoid() }, { id: nanoid() }],
        id: nanoid(),
      }))
      const slides = action.payload.continuities

      return {
        ...initialState,
        continuities: [
          { ...titleSlide, id: nanoid() },
          ...slidesFromProducts,
          ...slides,
          // ...action.payload.map((product) => ({
          //   ...defaultSlide,
          //   products: [
          //     product,
          //     { id: nanoid() },
          //     { id: nanoid() },
          //     { id: nanoid() },
          //     { id: nanoid() },
          //   ],
          //   id: nanoid(),
          // })),
        ],
      }
    },
    addProductToSlide: (state, action) => {
      const currentSlide = state.continuities[state.slideIndex]
      const products = [...currentSlide.products]
      products[action.payload.slot] = action.payload.product
      const existingProductIds = products.filter((p) => p?.id).map((p) => p.id)
      Object.keys(currentSlide.altImages).forEach((key) => {
        if (!existingProductIds.includes(Number(key))) {
          delete currentSlide.altImages[key]
        }
      })
      state.continuities[state.slideIndex].products = products
    },
    setAlternateImage: (state, action) => {
      const currentSlide = state.continuities[state.slideIndex]
      const { productId, altIdx } = action.payload
      currentSlide.altImages = {
        ...currentSlide.altImages,
        [productId]: altIdx,
      }
      state.continuities[state.slideIndex] = currentSlide
    },
    clear: () => {
      return initialState
    },
    updateDeckModifier: (state, action) => {
      state.defaultModifier = action.payload
      state.continuities = state.continuities.map((c) => ({
        ...c,
        priceModified: c.priceModifier !== action.payload ? true : c.priceModified,
        priceModifier: c.priceModified ? c.priceModifier : action.payload,
      }))
    },
    updateDeckLayout: (state, action) => {
      state.defaultLayout = action.payload
      state.continuities = state.continuities.map((c) => ({
        ...c,
        layoutModified: c.layout !== action.payload ? true : c.layoutModified,
        layout: c.layoutModified ? c.layout : action.payload,
        backgroundImage: c.layoutModified ? c.backgroundImage : state.defaultBackgroundImage,
      }))
    },
    updateDeckSlidesDefaultImages: (state, action) => {
      state.continuities = state.continuities.map((c) => {
        if (c.type === constants.CONTINUITY_TYPES.TITLE) {
          return c
        } else {
          return {
            ...c,
            layoutModified: true,
            layout: 'background',
            backgroundImage: action.payload,
          }
        }
      })
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createDeck.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
        }
      })
      .addCase(createDeck.rejected, (state, action) => {
        return state
      })
      .addCase(updateDeck.fulfilled, (state, action) => {
        // console.log('UPDATE FULFILLED', action.payload)
        return {
          ...state,
          ...action.payload,
        }
      })
      .addCase(updateDeck.rejected, (state, action) => {
        return state
      })
      .addCase(fetchDeck.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
        }
      })
      .addCase(fetchDeck.rejected, (state, action) => {
        return state
      })
  },
})

// Action creators are generated for each case reducer function
export const {
  startNewDeck,
  addSlide,
  updateCurrentSlide,
  setCurrentSlideIndex,
  addProductToSlide,
  updateCurrentDeck,
  setAlternateImage,
  clear,
  updateCurrentSlideType,
  duplicateSlide,
  deleteSlide,
  bulkCreateSlides,
  updateDeckModifier,
  updateDeckLayout,
  updateDeckSlidesDefaultImages,
  addMasterToSlide,
  addExistingSlide,
  createSlideFromProduct,
} = decksSlice.actions

export default decksSlice.reducer
