import { combineReducers } from '@reduxjs/toolkit'

import selectionsReducer from './selections/selectionsSlice'
import favoritesReducer from './favorites/favoritesSlice'
import globalsSlice from './globals/globalsSlice'
import decksReducer from './decks/decksSlice'
import userReducer from './user/userSlice'

/**
 * Main Date reducer
 */
export const appReducer = combineReducers({
  user: userReducer,
  favorites: favoritesReducer,
  selections: selectionsReducer,
  decks: decksReducer,
  globals: globalsSlice,
})
