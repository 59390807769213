import { ProductSelector } from 'components/continuityBuilder/ProductSelector'
import ProductSelectorBackground from 'components/continuityBuilder/ProductSelectorBackground'

import { useComputedPrice } from 'hooks/useComputedPrice'
import { classNames } from 'utils/helpers'

import { ReactComponent as GPSLogo } from 'assets/svgs/gps_logo.svg'
import EmptyImage from 'assets/images/empty_image.png'

export const SingleItemContinuity = ({ slide, onAddProduct }) => {
  const { computedPrice } = useComputedPrice(slide)

  return (
    <div className="relative flex h-full w-full items-center bg-white">
      <ProductSelectorBackground slide={slide}>
        <div
          className={classNames(
            slide.layout === 'bordered' || slide.layout === 'background' ? 'shadow-full-light' : '',
            'h-full w-full',
          )}
        >
          <ProductSelector
            product={slide?.products[0]}
            continuity={slide}
            idx={0}
            onAddProduct={onAddProduct}
            // size={'440px'}
            // size={slide.layout === 'bordered' || slide.layout === 'background' ? '400px' : '440px'} // TODO toggle this to add border
          />
        </div>
      </ProductSelectorBackground>
      {slide.products[0]?.name ? (
        <div className="flex h-full flex-col overflow-hidden p-5">
          <img
            src={slide.products[0]?.brandLogoImage ?? EmptyImage}
            alt="Brand Logo"
            className="mb-2 h-16 w-16 object-contain"
          />
          <h4 className="mb-2 w-full text-2xl font-semibold uppercase leading-6">
            {slide.products[0]?.name}
          </h4>
          <div className="mb-2 flex flex-col">
            <span className="text-xl font-semibold leading-5">{computedPrice}</span>
            <span className="text-xs text-gray-500">+ SHIPPING</span>
          </div>
          <p className="mt-1 text-xs text-gray-500">{slide.products[0]?.description}</p>
          <GPSLogo className="absolute bottom-2 right-5 h-14 w-14 text-purple-500" />
        </div>
      ) : (
        <div className="flex w-full flex-col items-start justify-center px-4">
          <div className="mb-2 h-6 w-2/5 rounded-full bg-gray-200" />
          <div className="mb-2 h-6 w-2/3 rounded-full bg-gray-200" />
          <div className="mb-2 h-12 w-1/4 rounded-xl bg-gray-200" />
          <div className="h-32 w-full rounded-xl bg-gray-200" />
        </div>
      )}
    </div>
  )
}
