import { Fragment, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useAlert } from 'react-alert'
import { Disclosure, Menu, Transition } from '@headlessui/react'

import { classNames, generateInitials } from '../utils/helpers'

import { ButtonSquare } from './buttons/ButtonSquare'
import { ExportButton } from './buttons/ExportButton'
import ExportCsvButton from './buttons/ExportCsvButton'
import ExportQuoteButton from './buttons/ExportQuoteButton'
import AddFileModal from './modals/AddFileModal'

import { logOut } from '../data/user'
import { selectIsAdmin, selectUser } from '../data/user/selectors'
import { clearDeck, startNewDeck, createDeck, updateDeck } from '../data/decks'
import { resetPriceAdjustOptions, setCurrentList, setFilters, setSearchTerm } from '../data/globals'

import { ReactComponent as GPSLogo } from '../assets/svgs/gps_logo.svg'

export const NavBar = ({ builder }) => {
  const alert = useAlert()
  const history = useHistory()
  const location = useLocation()

  const isAdmin = useSelector(selectIsAdmin)
  const user = useSelector(selectUser)
  const deck = useSelector((state) => state.decks)

  const [isSaveModalShowing, setIsSaveModalShowing] = useState(false)

  const profile = [
    {
      label: 'My Profile',
      handler: async () => {
        history.push(`/profile/${user?.id}`)
      },
    },
    {
      label: 'Search Products',
      handler: async () => {
        history.push('/home')
      },
    },
    {
      label: 'Sign out',
      handler: async () => {
        await logOut()
        localStorage.clear()
        history.replace('/login')
      },
    },
  ]

  if (isAdmin) {
    profile.splice(2, 0, { label: 'Manage Users', handler: () => history.push('/admin/user_list') })
  }

  function goHome() {
    history.push('/')
  }

  function startNew() {
    resetPriceAdjustOptions()
    startNewDeck()
    history.push('/builder')
  }

  async function handleCreate(newDeck, filename, dirId) {
    try {
      await createDeck({ newDeck, filename, dirId, user })
      setIsSaveModalShowing(false)
      alert.success('Save successful')
    } catch (error) {
      alert.error(error.message || 'Error saving continuity')
    }
  }

  async function handleUpdate(id, newDeck, filename, dirId) {
    try {
      await updateDeck({ id, newDeck, filename, dirId, user })
      setIsSaveModalShowing(false)
      alert.success('Update successful')
    } catch (error) {
      alert.error(error.message || 'Error updating continuity')
    }
  }

  function showSaveModal() {
    setIsSaveModalShowing(true)
  }

  async function handleSave(filename, dir) {
    const newMasterDeck = {
      ...deck,
    }
    if (newMasterDeck.id) {
      handleUpdate(newMasterDeck.id, newMasterDeck, filename, dir.directoryId)
    } else {
      newMasterDeck.userId = user?.id
      handleCreate(newMasterDeck, filename, dir.directoryId)
    }
  }

  function handleExit() {
    clearDeck()
    setFilters([])
    setSearchTerm('')
    setCurrentList(null)
    setTimeout(() => {
      history.replace('/products')
    }, 500)
  }

  return (
    <Disclosure
      as="nav"
      className={`fixed left-0 right-0 top-0 z-50 bg-gray-800`}
      style={{ minHeight: '67px' }}
    >
      {({ open }) => (
        <>
          <div className="max-w-8xl mx-auto px-8 sm:px-16">
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  {open ? (
                    <Disclosure.Button>
                      <GPSLogo onClick={goHome} className="h-6 cursor-pointer text-white md:h-8" />
                    </Disclosure.Button>
                  ) : (
                    <GPSLogo
                      onClick={goHome}
                      className="h-6 w-auto cursor-pointer text-white md:h-8"
                    />
                  )}
                </div>
              </div>
              <div className="block">
                <div className="ml-4 flex items-center md:ml-6">
                  {isAdmin && !location?.pathname?.includes('builder') && (
                    <ButtonSquare
                      text="+ NEW DECK"
                      onClick={startNew}
                      className="mr-4 h-9 text-[12px] sm:py-0 md:px-2 md:text-sm"
                    />
                  )}
                  {builder && (
                    <>
                      <ExportQuoteButton className="mr-4 sm:py-0 md:px-2 md:text-sm" />
                      <ExportCsvButton className="mr-4 h-9 sm:py-0 md:px-2 md:text-sm" />
                      <ExportButton className="mr-4 h-9 sm:py-0 md:px-2 md:text-sm" />
                      <ButtonSquare
                        text="SAVE"
                        onClick={() => showSaveModal(true)}
                        className="mr-4 h-9 text-[12px] sm:py-0 md:px-2 md:text-sm"
                      />
                      <ButtonSquare
                        text="EXIT BUILDER"
                        onClick={handleExit}
                        className="mr-4 h-9 px-2 py-0 text-[12px] sm:py-0 md:text-sm"
                      />
                    </>
                  )}

                  {/* Profile dropdown */}
                  {!builder && (
                    <Menu as="div" className="relative z-20 ml-3">
                      {({ open }) => (
                        <>
                          <div>
                            <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                              <span className="sr-only">Open user menu</span>
                              <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-500 text-sm text-white">
                                {generateInitials(user)}
                              </div>
                            </Menu.Button>
                          </div>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className="absolute right-0 mt-2 w-32 origin-top-right rounded-md bg-white py-1 shadow-full-dark ring-0 focus:outline-none"
                            >
                              {profile.map((item) => (
                                <Menu.Item key={item.label}>
                                  {({ active }) => (
                                    <button
                                      onClick={item.handler}
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block w-full px-2 py-1 text-left text-sm text-gray-800 outline-none ring-0 focus:outline-none',
                                      )}
                                    >
                                      {item.label}
                                    </button>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  )}
                </div>
              </div>
            </div>
          </div>

          <AddFileModal
            show={isSaveModalShowing}
            title="Save Deck"
            onAccept={handleSave}
            onClose={() => setIsSaveModalShowing(false)}
          />
        </>
      )}
    </Disclosure>
  )
}
