import { useState, useEffect, memo, forwardRef } from 'react'
import {
  PlusIcon,
  RefreshIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  MenuIcon,
} from '@heroicons/react/solid'

import { setAlternateImage } from '../../data/decks'
import { captureSlide, computePrimaryImage } from '../../utils/helpers'
import { IContinuity, IProduct } from 'types/modelTypes'
import { DraggableAttributes } from '@dnd-kit/core'
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'

interface IProductSelectorProps {
  product: IProduct
  continuity: IContinuity
  idx: number
  onAddProduct: (idx: number) => void
  horizontal?: boolean
  size?: string
  containerStyle?: Record<string, any>
  attributes?: DraggableAttributes
  listeners?: SyntheticListenerMap
  style?: Record<string, any>
}
const ProductSelector = memo(
  forwardRef(
    (
      {
        product,
        continuity,
        idx,
        onAddProduct,
        horizontal,
        // size = '250px',
        size,
        containerStyle,
        attributes,
        listeners,
        style,
      }: IProductSelectorProps,
      ref: React.ForwardedRef<HTMLDivElement>,
    ) => {
      const [image, setImage] = useState<string | null>(null)
      const [alternateImageIndex, setAlternateImageIndex] = useState(0)
      const [showImageOptions, setShowImageOptions] = useState(false)

      useEffect(() => {
        setImage(computePrimaryImage(continuity, continuity.products?.[idx]))
      }, [continuity, idx])

      function goNext() {
        if (Object.keys(product?.imageAssets).length === 1) return
        const index =
          product.imageAssets?.length - 1 > alternateImageIndex ? alternateImageIndex + 1 : 0
        setAlternateImageIndex(index)
        setAlternateImage({
          productId: product.id,
          altIdx: index,
        })
        setImage(product.imageAssets[index])
        captureSlide()
      }

      function goPrevious() {
        if (Object.keys(product?.imageAssets).length === 1) return
        const index =
          alternateImageIndex < 1 ? product.imageAssets?.length - 1 : alternateImageIndex - 1
        setAlternateImageIndex(index)
        setAlternateImage({
          productId: product.id,
          altIdx: index,
        })
        setImage(product.imageAssets[index])
        captureSlide()
      }

      function handleReplaceProduct() {
        setShowImageOptions(false)
        onAddProduct(idx)
      }

      return (
        <div
          ref={ref}
          key={idx}
          className={`relative flex h-full cursor-pointer items-center justify-center bg-white ${
            horizontal ? 'flex-col' : ''
          }`}
          style={{ ...containerStyle, ...style }}
        >
          {image ? (
            <>
              <img
                onClick={(e) => {
                  e.stopPropagation()
                  setShowImageOptions(!showImageOptions)
                }}
                className="h-full object-contain align-middle"
                style={{ height: size, width: size }}
                src={image}
              />
              {horizontal ? (
                <div className="min-h-14 line-clamp-2 h-14 text-ellipsis text-center">
                  {product.name}
                </div>
              ) : null}
              {showImageOptions && (
                <div className="ignore-capture absolute bottom-2 flex justify-evenly px-1">
                  <div className="flex w-full items-center justify-evenly rounded bg-white p-1 shadow-btn">
                    <button onClick={goPrevious} className="text-gray-500 hover:text-gray-300">
                      <ChevronLeftIcon className="mr-2 h-5 w-5" />
                    </button>
                    <button onClick={goNext} className="text-gray-500 hover:text-gray-300">
                      <ChevronRightIcon className="mr-2 h-5 w-5" />
                    </button>
                    <button
                      onClick={handleReplaceProduct}
                      className="flex flex-col items-center text-gray-500 hover:text-gray-400"
                    >
                      <RefreshIcon className="h-5 w-5" />
                      <span className="text-3xs">Replace Product</span>
                    </button>
                    <div
                      {...attributes}
                      {...listeners}
                      className="mx-2 flex flex-col items-center text-gray-500 hover:text-gray-400"
                    >
                      <MenuIcon className="h-5 w-5" />
                      <span className="text-3xs">Drag</span>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <button
              onClick={(e) => {
                e.stopPropagation()
                onAddProduct(idx)
              }}
              style={{ height: size, width: size }}
              className="h-full w-full p-3"
            >
              <div className="flex h-full w-full flex-col items-center justify-center rounded-lg border border-gray-500">
                <PlusIcon className="h-16 w-16 text-gray-400" />
                <p>Add Product</p>
              </div>
            </button>
          )}
        </div>
      )
    },
  ),
)

ProductSelector.displayName = 'ProductSelector'

export { ProductSelector }
