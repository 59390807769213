import { useState, useEffect, useCallback } from 'react'
import { useAlert } from 'react-alert'
import { Tab } from '@headlessui/react'
import throttle from 'lodash.throttle'
import axios from 'axios'

import {
  deleteSlide,
  duplicateSlide,
  setCurrentSlideIndex,
  updateCurrentDeck,
  updateCurrentSlide,
  updateCurrentSlideType,
  updateDeckLayout,
  updateDeckModifier,
  updateDeckSlidesDefaultImages,
} from 'data/decks'
import { selectCurrentSlide } from 'data/decks/selectors'
import { addPriceAdjustOption } from 'data/globals'
import { useAppSelector } from 'data/reduxHooks'

import { api } from 'services/api'

import constants from 'utils/constants'
import { captureSlide, classNames } from 'utils/helpers'

import { TextInput } from 'components/inputs/TextInput'
import ComboBox from 'components/inputs/ComboBox'
import { SelectMenu } from 'components/inputs/SelectMenu'
import { ButtonSquare } from 'components/buttons/ButtonSquare'
import { BudgetInfo } from 'components/continuityBuilder/BudgetInfo'
import { GenericModal } from 'components/modals/GenericModal'
import BackgroundImageModal from 'components/continuityBuilder/BackgroundImageModal'
import { WarningModal } from 'components/modals/WarningModal'

import { ContinuityType, IGalleryItem } from 'types/modelTypes'

export type ClientSelectOption = {
  name: string
  value: string | number
  persist?: boolean
}

type GenericSelectMenuOption = {
  id: number
  name: string
  value: string
}

export function BuilderControls() {
  const alert = useAlert()

  const deck = useAppSelector((state) => state.decks)
  const { currentSlide } = useAppSelector(selectCurrentSlide)
  const priceAdjustOptions = useAppSelector((state) => state.globals.priceAdjustOptions)

  const [clientOptions, setClientOptions] = useState<ClientSelectOption[]>([])
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [showAddClientModal, setShowAddClientModal] = useState(false)
  const [newClientName, setNewClientName] = useState('')
  const [newModifierValue, setNewModifierValue] = useState<number | null>(null)
  const [showCustomModifier, setShowCustomModifier] = useState(false)
  const [showBackgroundImageModal, setShowBackgroundImageModal] = useState(false)
  const [showWarning, setShowWarning] = useState(false)

  useEffect(() => {
    if (currentSlide.type === ContinuityType.title) {
      setSelectedTabIndex(0)
    } else {
      setSelectedTabIndex(1)
    }
  }, [currentSlide])

  useEffect(() => {
    fetchClients()
  }, [])

  function handleTabChange(idx: number) {
    setSelectedTabIndex(idx)
    if (idx === 0) {
      // if deck tab is selected, switch current slide to the title slide
      const titleSlideIndex = deck.continuities.findIndex(
        (cont) => cont.type === ContinuityType.title,
      )
      if (titleSlideIndex !== -1) {
        setCurrentSlideIndex(titleSlideIndex)
      }
    }
  }

  const fetchClients = async (selectLatest = false) => {
    try {
      const { data } = await api.Client.getAll()
      const asSelectOptions = data.map<ClientSelectOption>((d) => ({ name: d.name, value: d.id }))
      asSelectOptions.unshift({ name: '+ Add new client', value: 'add_new', persist: true })
      setClientOptions(asSelectOptions)
      if (selectLatest) {
        updateCurrentDeck({ recipient: asSelectOptions[asSelectOptions.length - 1].name })
        captureSlide()
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        alert.error(error.message)
      }
    }
  }

  function setCurrentSlideType(opt: GenericSelectMenuOption) {
    updateCurrentSlideType({ type: opt.value })
    captureSlide()
  }

  function setCurrentSlideLayout(opt: GenericSelectMenuOption) {
    updateCurrentSlide({ layout: opt.value })
    captureSlide()
  }

  function handleDeckTitleChange(val: string) {
    updateCurrentDeck({ title: val })
  }

  const throttledCaptureSlide = useCallback(throttle(captureSlide, 2000), [])

  function handleSlideTitleChange(val: string) {
    updateCurrentSlide({ title: val })
    throttledCaptureSlide()
  }

  function handleClientSelect(opt: ClientSelectOption) {
    if (opt.value === 'add_new') {
      setShowAddClientModal(true)
    } else {
      updateCurrentDeck({ recipient: opt.name })
      captureSlide()
    }
  }

  function handleBudgetChange(val: string) {
    updateCurrentDeck({ budget: val })
  }

  function handleModifierChange(opt: GenericSelectMenuOption) {
    if (opt.value === 'custom') {
      setShowCustomModifier(true)
    } else {
      setShowCustomModifier(false)
      updateCurrentSlide({ priceModifier: opt.value })
    }
  }

  function handleDeckModifierChange(opt: GenericSelectMenuOption) {
    if (opt.value === 'custom') {
      setShowCustomModifier(true)
    } else {
      setShowCustomModifier(false)
      updateDeckModifier(opt.value)
    }
  }

  function handleDeckLayoutChange(opt: GenericSelectMenuOption) {
    updateDeckLayout(opt.value)
  }

  async function handleAddClient() {
    try {
      await api.Client.create({ name: newClientName })
      setShowAddClientModal(false)
      fetchClients(true)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        alert.error(error.message)
      }
    }
  }

  async function handleAddModifier() {
    if (
      !newModifierValue ||
      typeof Number(newModifierValue) !== 'number' ||
      newModifierValue > 100 ||
      newModifierValue < 0
    ) {
      alert.error('Please enter a valid number')
      return
    }
    const newOptValue = 1 - newModifierValue / 100
    if (priceAdjustOptions.find((opt) => opt.value === newOptValue)) {
      alert.error('This value is already in use')
      return
    }
    addPriceAdjustOption({ value: newOptValue, name: newModifierValue + '%' })
    setShowCustomModifier(false)
  }

  function onAcceptWarning() {
    deleteSlide()
    setShowWarning(false)
  }

  return (
    <>
      <Tab.Group selectedIndex={selectedTabIndex} onChange={handleTabChange}>
        <Tab.List className="flex w-full p-1">
          <Tab
            className={({ selected }) =>
              classNames(
                selected
                  ? 'border-purple-500 text-gray-900'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                'flex-1 whitespace-nowrap border-b-2 px-1 py-2 text-left text-sm font-medium outline-none ring-0 focus:outline-none',
              )
            }
          >
            Deck
          </Tab>
          {currentSlide.type !== ContinuityType.title && (
            <Tab
              className={({ selected }) =>
                classNames(
                  selected
                    ? 'border-purple-500 text-gray-900'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'flex-1 whitespace-nowrap border-b-2 px-1 py-2 text-left text-sm font-medium outline-none ring-0 focus:outline-none',
                )
              }
            >
              Slide
            </Tab>
          )}
        </Tab.List>

        <Tab.Panels className="px-2" style={{ height: '85%' }}>
          {/* Deck Panel */}
          <Tab.Panel
            className={classNames('w-5/6 bg-white', 'h-full focus:outline-none focus:ring-0')}
          >
            <TextInput
              label="Title of Deck"
              srOnly={false}
              onChange={handleDeckTitleChange}
              value={deck.title || ''}
              containerClass="mb-5"
            />
            <ComboBox
              items={clientOptions}
              selected={clientOptions.find((opt) => opt.name === deck.recipient)}
              label="Choose Client"
              onChange={handleClientSelect}
              containerClass="mb-5"
            />
            <TextInput
              containerClass="mb-5"
              prefix={true}
              label="Weekly Budget ($)"
              srOnly={false}
              onChange={handleBudgetChange}
              type="number"
              value={deck.budget || ''}
              onBlur={(e) => {
                handleBudgetChange(Number(deck.budget).toFixed(2))
              }}
            />
            <SelectMenu
              className="mb-5"
              label="Deck Price Modifier"
              options={priceAdjustOptions as GenericSelectMenuOption[]}
              onChange={handleDeckModifierChange}
              value={
                priceAdjustOptions?.find((opt) => opt.value === deck.defaultModifier) ||
                priceAdjustOptions?.[5]
              }
            />
            <SelectMenu
              className="mb-5"
              label="Layout Modifier"
              options={constants.SLIDE_LAYOUT_OPTIONS}
              onChange={handleDeckLayoutChange}
              value={
                constants.SLIDE_LAYOUT_OPTIONS.find((opt) => opt.value === deck.defaultLayout) ||
                constants.SLIDE_LAYOUT_OPTIONS[0]
              }
            />
            {deck.defaultLayout === 'background' && (
              <div className="mx-auto w-full">
                <ButtonSquare
                  small
                  outline
                  textColor="text-gray-700"
                  text="Choose Image"
                  onClick={() => setShowBackgroundImageModal(true)}
                />
              </div>
            )}
          </Tab.Panel>
          {/* Slide Panel */}
          <Tab.Panel
            style={{ height: '100%' }}
            className="flex flex-col bg-white focus:outline-none focus:ring-0"
          >
            <div className="flex w-5/6 flex-grow flex-col">
              <TextInput
                label="Title of Slide"
                srOnly={false}
                onChange={handleSlideTitleChange}
                value={currentSlide.title || ''}
                containerClass="mb-5"
              />
              <SelectMenu
                className="mb-5"
                label="Slide Type"
                options={constants.SLIDE_TYPE_OPTIONS}
                onChange={setCurrentSlideType}
                value={
                  constants.SLIDE_TYPE_OPTIONS.find((opt) => opt.value === currentSlide.type) ||
                  constants.SLIDE_TYPE_OPTIONS[0]
                }
              />
              <SelectMenu
                className="mb-5"
                label="Layout"
                options={constants.SLIDE_LAYOUT_OPTIONS}
                disabled={currentSlide.type === ContinuityType.quad_horizontal}
                onChange={setCurrentSlideLayout}
                value={
                  constants.SLIDE_LAYOUT_OPTIONS.find((opt) => opt.value === currentSlide.layout) ||
                  constants.SLIDE_LAYOUT_OPTIONS[0]
                }
              />
              <SelectMenu
                className="mb-5"
                label="Slide Price Modifier"
                options={priceAdjustOptions as GenericSelectMenuOption[]}
                onChange={handleModifierChange}
                value={
                  priceAdjustOptions?.find((opt) => opt.value === currentSlide.priceModifier) ??
                  priceAdjustOptions?.[3]
                }
              />
              {currentSlide.type !== ContinuityType.single && (
                <BudgetInfo priceModifier={Number(currentSlide.priceModifier)} />
              )}
            </div>
            <div className="flex flex-col items-center justify-end">
              <ButtonSquare
                color="bg-gray"
                onClick={duplicateSlide}
                text="Duplicate Slide"
                className="mb-2 w-5/6"
              />
              <ButtonSquare
                onClick={() => setShowWarning(true)}
                text="Delete Slide"
                color="bg-red"
                className="w-5/6"
              />
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
      <GenericModal
        title="Add a new client"
        show={showAddClientModal}
        onClose={() => setShowAddClientModal(false)}
        onAccept={handleAddClient}
      >
        <form>
          <TextInput label="Client name" onChange={setNewClientName} placeholder="Client name" />
        </form>
      </GenericModal>
      <GenericModal
        title="Add a custom modifier"
        show={showCustomModifier}
        onClose={() => setShowCustomModifier(false)}
        onAccept={handleAddModifier}
      >
        <form>
          <TextInput
            type="number"
            onChange={(val: string) => setNewModifierValue(Number(val))}
            placeholder="New modifier ex: 19"
          />
        </form>
      </GenericModal>
      <BackgroundImageModal
        onClose={() => setShowBackgroundImageModal(false)}
        onSelect={(img: IGalleryItem) => {
          updateCurrentDeck({ defaultBackgroundImage: img.url })
          if (selectedTabIndex === 0) {
            // we are setting image on the whole deck so update all slides
            updateDeckSlidesDefaultImages(img.url)
          }
          setShowBackgroundImageModal(false)
        }}
        show={showBackgroundImageModal}
      />
      <WarningModal
        onAccept={onAcceptWarning}
        onClose={() => setShowWarning(false)}
        title="Warning"
        content="Are you sure you want to delete this slide?"
        show={showWarning}
      />
    </>
  )
}
