import { useState } from 'react'

import { GenericModal } from './GenericModal'
import { TextInput } from '../inputs/TextInput'

export default function AddFolderModal({ title, show, onClose = () => {}, onAccept = () => {} }) {
  const [folderName, setFolderName] = useState('')

  function handleAccept() {
    onAccept(folderName)
  }

  function renderContent() {
    return <TextInput label="Folder Name" onChange={setFolderName} />
  }
  return (
    <GenericModal title={title} show={show} onClose={onClose} onAccept={handleAccept}>
      {renderContent()}
    </GenericModal>
  )
}
