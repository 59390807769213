import { store } from '../store'
import { setUser as su, logOut as lo, updateUser as uu } from './userSlice'

import { IUser } from '../../types/modelTypes'

export const setUser = (data: IUser) => store.dispatch(su(data))

export const logOut = () => store.dispatch(lo())

export const updateUser = (data: IUser) => store.dispatch(uu(data))
