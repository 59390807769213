import { useState } from 'react'
import {
  DndContext,
  DragOverlay,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
  DragStartEvent,
  DragEndEvent,
  UniqueIdentifier,
} from '@dnd-kit/core'
import { arrayMove, rectSortingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { ProductSelector } from 'components/continuityBuilder/ProductSelector'
import ProductSelectorBackground from 'components/continuityBuilder/ProductSelectorBackground'

import { useComputedPrice } from 'hooks/useComputedPrice'
import { classNames } from 'utils/helpers'

import { updateCurrentSlide } from 'data/decks'

import { ReactComponent as GPSLogo } from 'assets/svgs/gps_logo.svg'

import { IContinuity, IProduct } from 'types/modelTypes'

interface ISortableItemProps {
  id: string | number
  value: IProduct
  idx: number
  onAddProduct: (idx: number) => void
  slide: IContinuity
}
function SortableItem({ id, value, idx, onAddProduct, slide }: ISortableItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: String(id),
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <ProductSelector
      ref={setNodeRef}
      attributes={attributes}
      listeners={listeners}
      style={style}
      key={idx}
      product={value}
      continuity={slide}
      idx={idx}
      onAddProduct={onAddProduct}
    />
  )
}

interface IFourItemContinuityProps {
  slide: IContinuity
  onAddProduct: (idx: number) => void
}
export const FourItemContinuity = ({ slide, onAddProduct }: IFourItemContinuityProps) => {
  const { computedPrice } = useComputedPrice(slide)
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(PointerSensor),
  )

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)

  function handleDragStart(event: DragStartEvent) {
    const { active } = event
    setActiveId(active.id)
  }

  async function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event
    if (active.id !== over?.id) {
      const items = [...slide.products]
      const oldIndex = items.findIndex((i) => String(i.id) === active.id)
      const newIndex = items.findIndex((i) => String(i.id) === over?.id)
      const newOrderedProducts = arrayMove(items, oldIndex, newIndex)
      updateCurrentSlide({
        products: newOrderedProducts,
      })
    }

    setActiveId(null)
  }

  function handleDragCancel() {
    setActiveId(null)
  }

  return (
    <div className="relative flex h-full w-full items-center bg-white">
      <ProductSelectorBackground slide={slide}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
        >
          <SortableContext
            items={slide.products.map((p) => String(p.id))}
            strategy={rectSortingStrategy}
          >
            <div
              className={classNames(
                'grid h-full grid-cols-2 grid-rows-2',
                slide.layout === 'bordered' || slide.layout === 'background'
                  ? 'shadow-full-light'
                  : '',
              )}
            >
              {slide.products.slice(0, 4).map((p, idx) => {
                return (
                  <SortableItem
                    key={p.id}
                    idx={idx}
                    id={p.id}
                    value={p}
                    slide={slide}
                    onAddProduct={onAddProduct}
                  />
                )
              })}
            </div>
          </SortableContext>
          <DragOverlay>
            {activeId ? (
              <ProductSelector
                ref={null}
                listeners={{}}
                style={{}}
                product={slide.products.find((p) => String(p.id) === activeId)!}
                continuity={slide}
                idx={slide.products.findIndex((p) => String(p.id) === activeId)}
                onAddProduct={() => {}}
                size={
                  slide.layout === 'bordered' || slide.layout === 'background' ? '180px' : '220px'
                }
              />
            ) : null}
          </DragOverlay>
        </DndContext>
      </ProductSelectorBackground>
      {slide.products.some((p) => Object.keys(p).length > 1) ? (
        <div className="flex h-full flex-col overflow-hidden p-5">
          <div className="mb-2 h-16 w-16" />
          <h4 className="mb-2 w-full text-2xl font-semibold uppercase leading-6">{slide.title}</h4>
          <div className="mb-2 flex flex-col">
            <span className="text-xl font-semibold leading-5">{computedPrice}</span>
            <span className="text-xs text-gray-500">+ SHIPPING PER WEEK</span>
          </div>
          <ul className="ml-3 list-disc">
            {slide.products.map((p, idx) => {
              return p.name ? (
                <li key={idx} className="mt-1 text-xs text-gray-500">
                  {p?.name}
                </li>
              ) : (
                <span key={idx} />
              )
            })}
          </ul>
          <GPSLogo className="absolute bottom-2 right-5 h-14 w-14 text-purple-500" />
        </div>
      ) : (
        <div className="flex w-full flex-col items-start justify-center px-4">
          <div className="mb-2 h-6 w-2/5 rounded-full bg-gray-200" />
          <div className="mb-2 h-6 w-2/3 rounded-full bg-gray-200" />
          <div className="mb-2 h-12 w-1/4 rounded-xl bg-gray-200" />
          <div className="h-32 w-full rounded-xl bg-gray-200" />
        </div>
      )}
    </div>
  )
}
