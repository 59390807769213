import { useHistory, useLocation } from 'react-router-dom'

import { BackButton } from 'components/buttons/BackButton'
import { SlideDetailView } from 'components/continuityBuilder/SlideDetailView'

// Types
import { ISlideDetailLocationState } from 'types/routeTypes'
import { IContinuity } from 'types/modelTypes'

import { addExistingSlide } from 'data/decks'

export const SlideDetail = () => {
  const history = useHistory()
  const location = useLocation<ISlideDetailLocationState>()

  const { continuity, fromBuilder } = location.state || {}

  function handleBackNavigation() {
    if (fromBuilder) {
      history.push({
        pathname: `/builder`,
        // state: { id, page, sortState },
      })
    } else {
      history.push({
        pathname: '/products',
        // TODO pass state to set products view to 'continuities' instead of 'products'
        state: { showContinuities: true },
      })
    }
  }

  function handleAddSlideToDeck(cont: IContinuity) {
    addExistingSlide(cont)
    handleBackNavigation()
  }

  return (
    <div className="scrollbar flex h-full w-full flex-col overflow-y-auto px-8 sm:px-16">
      <div className="flex items-center py-3">
        <BackButton text="Go back" onClick={() => handleBackNavigation()} />
      </div>
      <SlideDetailView
        continuity={continuity}
        fromBuilder={fromBuilder}
        onAddComplete={handleAddSlideToDeck}
      />
    </div>
  )
}
