import { useEffect, useState } from 'react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { Combobox } from '@headlessui/react'

import { classNames } from '../../utils/helpers'

export default function ComboBox({ items = [], label, onChange, selected, containerClass }) {
  const [query, setQuery] = useState('')
  const [selectedItem, setSelectedItem] = useState(items[0])

  useEffect(() => {
    setSelectedItem(selected)
  }, [selected])

  const filteredItems =
    query === ''
      ? items
      : items.filter((item) => {
          return item.name.toLowerCase().includes(query.toLowerCase()) || item.persist
        })

  function handleChange(opt) {
    setSelectedItem(opt)
    onChange(opt)
    setQuery('')
  }

  return (
    <Combobox as="div" value={selectedItem} onChange={handleChange} className={containerClass}>
      {label && <Combobox.Label className="block text-sm font-semibold">{label}</Combobox.Label>}
      <div className="relative">
        <Combobox.Input
          className="w-full border border-gray-700 bg-white py-2 pl-3 pr-10 shadow-sm outline-none ring-0 focus:border-purple-500 focus:outline-none focus:ring-0 sm:text-sm"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(item) => item.name}
        />
        <Combobox.Button>
          <div className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        </Combobox.Button>

        <Combobox.Options className="scrollbar absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-full-dark ring-0 focus:outline-none sm:text-sm">
          {filteredItems.map((item) => (
            <Combobox.Option
              key={item.value}
              value={item}
              className={({ active }) =>
                classNames(
                  'relative cursor-default select-none py-2 pl-3 pr-9 text-gray-800',
                  active ? 'bg-gray-100' : '',
                )
              }
            >
              {({ active, selected }) => (
                <>
                  <span className={classNames('block truncate', selected && 'font-semibold')}>
                    {item.name}
                  </span>

                  {selected && (
                    <span
                      className={classNames(
                        'absolute inset-y-0 right-0 flex items-center pr-4 text-gray-800',
                      )}
                    >
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  )}
                </>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </div>
    </Combobox>
  )
}
