import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { updateCurrentSlide } from 'data/decks'
import { selectCurrentSlide } from 'data/decks/selectors'
import { useAppSelector } from 'data/reduxHooks'

import { captureSlide } from 'utils/helpers'

import { FourItemContinuity } from 'components/continuityBuilder/layouts/FourItemContinuity'
import FourItemContinuityHoriztonal from 'components/continuityBuilder/layouts/FourItemContinuity--Horizontal'
import { SingleItemContinuity } from 'components/continuityBuilder/layouts/SingleItemContinuity'
import { FiveItemContinuity } from 'components/continuityBuilder/layouts/FiveItemContinuity'
import AddProductModal from 'components/modals/AddProductModal'

import { ContinuityType } from 'types/modelTypes'

export const BUIDLER_DIMENSIONS = {
  width: 700,
  height: 394,
}

interface IBuilderCoreProps {
  showAddProductsModalOnLoad: boolean
}
export const BuilderCore = ({ showAddProductsModalOnLoad }: IBuilderCoreProps) => {
  const history = useHistory()

  const { currentSlide, currentSlideIndex } = useAppSelector(selectCurrentSlide)
  const currentDeck = useAppSelector((state) => state.decks)

  const [showAddProductModal, setShowAddProductModal] = useState(false)
  const [slot, setSlot] = useState(0)

  useEffect(() => {
    // Creates a thumbanil of the currently visible slide
    setTimeout(() => {
      captureSlide()
    }, 200)
  }, [currentSlideIndex])

  useEffect(() => {
    if (showAddProductsModalOnLoad) {
      setShowAddProductModal(true)
    }
  }, [showAddProductsModalOnLoad])

  function addProduct(slotNum: number) {
    setSlot(slotNum)
    setShowAddProductModal(true)
  }

  function setName(value: string) {
    updateCurrentSlide({
      name: value.toUpperCase(),
    })
  }

  return (
    <div
      className="relative mx-auto h-0 w-full"
      style={{
        paddingBottom: '56.25%',
      }}
    >
      <div
        id="capture"
        className="absolute inset-0 h-full w-full shadow-full-dark"
        style={{
          minWidth: BUIDLER_DIMENSIONS.width + 'px',
          minHeight: BUIDLER_DIMENSIONS.height + 'px',
        }}
      >
        {/* Create cover slide */}
        {currentSlide.type === ContinuityType.title ? (
          <div className="relative">
            {currentDeck.recipient && (
              <div
                className="absolute mr-2 text-white"
                style={{ left: '58%', top: '42%', width: '40%' }}
              >
                <p className="text-center font-bold" style={{ fontSize: '28px' }}>
                  EXCLUSIVELY MADE FOR
                </p>
                <p className="text-center text-2xl uppercase">{currentDeck.recipient}</p>
              </div>
            )}
            <img
              src={
                'https://gps-solutions-assets.s3.us-west-2.amazonaws.com/gps_title_slide_black.png'
                // 'https://gps-solutions-assets.s3.us-west-2.amazonaws.com/gps_title_slide_blank.jpg'
              }
              className="w-full object-contain"
            />
          </div>
        ) : currentSlide.type === ContinuityType.single ? (
          // single item slide
          <SingleItemContinuity slide={currentSlide} onAddProduct={addProduct} />
        ) : currentSlide.type === ContinuityType.quad ? (
          // four item slide
          <FourItemContinuity
            slide={currentSlide}
            onAddProduct={addProduct}
            // onNameChange={setName}
          />
        ) : currentSlide.type === ContinuityType.quad_horizontal ? (
          <FourItemContinuityHoriztonal
            slide={currentSlide}
            onAddProduct={addProduct}
            // onNameChange={setName}
          />
        ) : currentSlide.type === ContinuityType.quint ? (
          // five item slide
          <FiveItemContinuity
            slide={currentSlide}
            onAddProduct={addProduct}
            // onNameChange={setName}
          />
        ) : null}
      </div>
      <AddProductModal
        show={showAddProductModal}
        title={`Add products${currentDeck.title ? ` to ${currentDeck.title}` : ''}`}
        slot={slot}
        defaultIndex={showAddProductsModalOnLoad ? 1 : 0}
        onClose={() => {
          history.replace('/builder')
          setShowAddProductModal(false)
        }}
      />
    </div>
  )
}
