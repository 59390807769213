import React from 'react'
import ReactDOM from 'react-dom/client'
import Amplify from 'aws-amplify'
import { BrowserRouter, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'
import { SkeletonTheme } from 'react-loading-skeleton'

import { AlertTemplate } from './components/AlertTemplate'
import { SwitchRouter } from './components/SwitchRouter'
import ErrorBoundary from './components/ErrorBoundary'
import { PageListener } from './components/PageListener'

import { Dashboard } from './layouts/Dashboard'

import { NewUserResetPassword } from './pages/auth/NewUserResetPassword'
import { EmailVerification } from './pages/auth/EmailVerification'
import { ForgotPassword } from './pages/auth/ForgotPassword'
import { ResetPassword } from './pages/auth/ResetPassword'
import { SignUp } from './pages/auth/SignUp'
import { Login } from './pages/auth/Login'

import { store } from './data/store'

import 'react-loading-skeleton/dist/skeleton.css'

/* Amplify config */
import awsconfig from './aws-exports'
Amplify.configure(awsconfig)

import './index.css'

import reportWebVitals from './reportWebVitals'

export const queryClient = new QueryClient()

// const MEASUREMENT_ID = 'G-LYFLTJVGH1' // Staging analytics id
// ReactGA.initialize('G-LYFLTJVGH1')
if (process.env.REACT_APP_MEASUREMENT_ID) {
  ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID)
}

const tagManagerArgs = {
  gtmId: 'GTM-T43G4F5',
}

TagManager.initialize(tagManagerArgs)

// optional configuration
const alertOptions = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE,
}

ReactDOM.createRoot(document.getElementById('root') as Element).render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <AlertProvider template={AlertTemplate} {...alertOptions}>
          <BrowserRouter>
            <PageListener>
              <ErrorBoundary>
                <SkeletonTheme baseColor="#f2f2f2" highlightColor="#ddd">
                  <SwitchRouter>
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/signup" component={SignUp} />
                    <Route exact path="/forgot_password" component={ForgotPassword} />
                    <Route exact path="/reset_password" component={ResetPassword} />
                    <Route exact path="/set_password" component={NewUserResetPassword} />
                    <Route exact path="/verify_email" component={EmailVerification} />
                    <Route path="/" component={Dashboard} />
                  </SwitchRouter>
                </SkeletonTheme>
              </ErrorBoundary>
            </PageListener>
          </BrowserRouter>
        </AlertProvider>
        <ReactQueryDevtools initialIsOpen />
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
