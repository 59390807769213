import { useCallback, useState } from 'react'
import { useAlert } from 'react-alert'
import { useSelector } from 'react-redux'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import { ChevronDownIcon } from '@heroicons/react/solid'

import { selectIsAdmin, selectOrganizationMarkup } from '../data/user/selectors'
import { api } from '../services/api'

import { Checkbox } from './inputs/Checkbox'
import { CustomPriceRange } from './filters/CustomPriceRange'
import { createFilterFromAttribute } from './filters/utils'
import { useInit } from '../utils/hooks'

const PRICE_FILTERS = [
  [5, 10],
  [10, 15],
  [15, 20],
  [20, 25],
  [25, 50],
  [50, 75],
  [75, 100],
]

const INVENTORY_FILTERS = [
  [1, 250],
  [251, 500],
  [501, 1000],
  [1001, 2000],
  [2001, 5000],
  [5001, 10000],
]

export const ProductFilters = ({ filters, setFilters, showExclusivesOnly }) => {
  const alert = useAlert()

  const isAdmin = useSelector(selectIsAdmin)
  const orgMarkup = useSelector(selectOrganizationMarkup)

  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [brands, setBrands] = useState([])
  const [colors, setColors] = useState([])
  const [vendors, setVendors] = useState([])
  const [tags, setTags] = useState([])
  const [materials, setMaterials] = useState([])

  const getProductAttributes = useCallback(async () => {
    try {
      const { data, meta } = await api.ProductAttributes.getAll(true)
      setColors(data.Color)
      setBrands(data.Brand)
      setCategories(data.Category)
      setTags(data.Tags)
      setVendors(data.Vendor)
      setSubCategories(data.Subcategory)
      setMaterials(data.Material)
    } catch (error) {
      alert.error(error.message || 'Could not find tag filters')
    }
  }, [alert])

  useInit(() => {
    getProductAttributes()
  })

  return (
    <Accordion
      allowMultipleExpanded={true}
      allowZeroExpanded={true}
      // onChange={(val, w) => { }}
    >
      <AccordionItem uuid="exclusive">
        <AccordionItemHeading className="transition-colors sticky left-0 top-0 z-10 -ml-1 mb-2 h-6 bg-white duration-200 hover:bg-gray-100">
          <AccordionItemButton className="flex items-center outline-none">
            <ChevronDownIcon className="h-4 w-4" />
            GPS Exclusive
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          {!showExclusivesOnly && (
            <li className="transition-colors flex bg-white px-2 py-1 text-xs outline-none duration-200 hover:bg-gray-100">
              <Checkbox
                id="exclusive_box"
                checked={filters.findIndex((f) => f.prop?.value === 'exclusive') > -1}
                onChange={(val) => {
                  if (val) {
                    const newFilter = createFilterFromAttribute('exclusive', 'Exclusive', 'or')
                    setFilters([...filters, newFilter])
                  } else {
                    const newFilters = filters.filter((f) => f.prop?.value !== 'exclusive')
                    setFilters(newFilters)
                  }
                }}
                label="GPS Exclusives"
                labelStyle={{ fontSize: '0.75rem', width: '100%' }}
                containerStyle="w-full"
              />
            </li>
          )}
          <li className="transition-colors flex bg-white px-2 py-1 text-xs outline-none duration-200 hover:bg-gray-100">
            <Checkbox
              id="warehouse_box"
              checked={filters.findIndex((f) => f.prop?.value === 'warehouse') > -1}
              onChange={(val) => {
                if (val) {
                  const newFilter = createFilterFromAttribute('warehouse', 'Warehouse', 'or')
                  setFilters([...filters, newFilter])
                } else {
                  const newFilters = filters.filter((f) => f.prop?.value !== 'warehouse')
                  setFilters(newFilters)
                }
              }}
              label="GPS Warehouse"
              labelStyle={{ fontSize: '0.75rem', width: '100%' }}
              containerStyle="w-full"
            />
          </li>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem uuid="categories">
        <AccordionItemHeading className="transition-colors sticky left-0 top-0 z-10 -ml-1 mb-2 h-6 bg-white duration-200 hover:bg-gray-100">
          <AccordionItemButton className="flex items-center outline-none">
            <ChevronDownIcon className="h-4 w-4" />
            Categories
          </AccordionItemButton>
        </AccordionItemHeading>
        {/* <CategoryFilterItem categories={categories} filters={filters} setFilters={setFilters} /> */}
        <AccordionItemPanel>
          {categories?.map((cat) => (
            <li
              key={`${cat.id}_cat`}
              className={`transition-colors flex bg-white py-1 pl-1 text-xs outline-none duration-200 hover:bg-gray-100`}
            >
              <Checkbox
                id={`${cat.id}_cat`}
                checked={filters.findIndex((f) => f.attr?.[0].value === cat.name) > -1}
                onChange={(val) => {
                  if (val) {
                    const newFilter = createFilterFromAttribute('category', cat.name, 'or')
                    setFilters([...filters, newFilter])
                  } else {
                    const newFilters = filters.filter(
                      (f) => f.prop?.value === 'category' && f.attr[0].value !== cat.name,
                    )
                    setFilters(newFilters)
                  }
                }}
                label={cat.name}
                labelStyle={{ fontSize: '0.75rem', width: '100%' }}
                containerStyle="w-full"
              />
            </li>
          ))}
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem uuid="subcategories">
        <AccordionItemHeading className="transition-colors sticky left-0 top-0 z-10 -ml-1 mb-2 h-6 bg-white duration-200 hover:bg-gray-100">
          <AccordionItemButton className="flex items-center outline-none">
            <ChevronDownIcon className="h-4 w-4" />
            Sub Categories
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          {subCategories?.map((cat) => (
            <li
              key={`${cat.id}_scat`}
              className={`transition-colors flex bg-white py-1 pl-1 text-xs outline-none duration-200 hover:bg-gray-100`}
            >
              <Checkbox
                id={`${cat.id}_scat`}
                checked={filters.findIndex((f) => f.attr?.[0].value === cat.name) > -1}
                onChange={(val) => {
                  if (val) {
                    const newFilter = createFilterFromAttribute('subCategory', cat.name, 'or')
                    setFilters([...filters, newFilter])
                  } else {
                    const newFilters = filters.filter(
                      (f) => f.prop?.value === 'subCategory' && f.attr[0].value !== cat.name,
                    )
                    setFilters(newFilters)
                  }
                }}
                label={cat.name}
                labelStyle={{ fontSize: '0.75rem', width: '100%' }}
                containerStyle="w-full"
              />
            </li>
          ))}
        </AccordionItemPanel>
      </AccordionItem>

      {isAdmin ? (
        <AccordionItem uuid="cost">
          <AccordionItemHeading className="transition-colors sticky left-0 top-0 z-10 -ml-1 mb-2 h-6 bg-white duration-200 hover:bg-gray-100">
            <AccordionItemButton className="flex items-center outline-none">
              <ChevronDownIcon className="h-4 w-4" />
              Cost
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className="pl-1 pr-2 text-xs outline-none">
              <CustomPriceRange
                type="cost"
                filters={filters}
                checkedProp={
                  !!filters.find((f) => f.operator.type.includes('customrange')) || false
                }
                onChange={(val, filterText) => {
                  const newFilters = filters.filter((f) => !f.operator.type.includes('customrange'))
                  if (val) {
                    // only one can be selected at a time
                    const newFilter = createFilterFromAttribute(
                      'costrange',
                      filterText,
                      'between',
                      'customrange',
                    )
                    setFilters([...newFilters, newFilter])
                  } else {
                    setFilters(newFilters)
                  }
                }}
              />
            </div>
            {PRICE_FILTERS.map((f, idx) => {
              const filterText = `cost+${f[0]},${f[1]}`
              return (
                <li
                  key={f}
                  className="transition-colors flex bg-white py-1 pl-1 text-xs outline-none duration-200 hover:bg-gray-100"
                >
                  <Checkbox
                    id={`cost_${idx}`}
                    checked={!!filters.find((f) => f.attr[0].value === filterText) || false}
                    onChange={(val) => {
                      // only one can be selected at a time
                      const newFilters = filters.filter((f) => f.prop?.value !== 'cost')
                      if (val) {
                        const ranges = []
                        ranges.push(
                          createFilterFromAttribute(
                            'cost',
                            f[0] - f[0] * orgMarkup.tier1,
                            'gte',
                            'int',
                            'Is greater than',
                          ),
                        )
                        if (f[1]) {
                          ranges.push(
                            createFilterFromAttribute(
                              'cost',
                              f[1] - f[1] * orgMarkup.tier1,
                              'lte',
                              'int',
                              'Is less than',
                            ),
                          )
                        }
                        setFilters([...newFilters, ...ranges])
                      } else {
                        setFilters(newFilters)
                      }
                    }}
                    label={f.join(' - ')}
                    labelStyle={{ fontSize: '0.75rem', width: '100%' }}
                    containerStyle="w-full"
                  />
                </li>
              )
            })}
          </AccordionItemPanel>
        </AccordionItem>
      ) : (
        <AccordionItem uuid="price">
          <AccordionItemHeading className="transition-colors sticky left-0 top-0 z-10 -ml-1 mb-2 h-6 bg-white duration-200 hover:bg-gray-100">
            <AccordionItemButton className="flex items-center outline-none">
              <ChevronDownIcon className="h-4 w-4" />
              Price
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className="pl-1 pr-2 text-xs outline-none">
              <CustomPriceRange
                type="cost"
                filters={filters}
                checkedProp={
                  !!filters.find((fi) => {
                    // checked if filter object if greater than and first number in filter matches
                    return (
                      (fi.operator?.value === 'gte' || fi.operator?.value === 'lte') &&
                      fi.operator?.label.includes('custom')
                    )
                  }) || false
                }
                onChange={(val, filterText, start, end) => {
                  const newFilters = filters.filter((f) => !f.operator?.type.includes('cost'))
                  if (val) {
                    const ranges = []
                    if (!!start) {
                      ranges.push(
                        createFilterFromAttribute(
                          'cost',
                          start,
                          'gte',
                          'int',
                          'Is greater than custom',
                        ),
                      )
                    }
                    if (!!end) {
                      ranges.push(
                        createFilterFromAttribute('cost', end, 'lte', 'int', 'Is less than custom'),
                      )
                    }
                    setFilters([...newFilters, ...ranges])
                  } else {
                    setFilters(newFilters)
                  }
                }}
              />
            </div>
            {PRICE_FILTERS.map((f, idx) => {
              return (
                <li
                  key={f}
                  className="transition-colors flex bg-white py-1 pl-1 text-xs outline-none duration-200 hover:bg-gray-100"
                >
                  <Checkbox
                    id={`price_${idx}`}
                    checked={
                      !!filters.find((fi) => {
                        // checked if filter object if greater than and first number in filter matches
                        return fi.operator?.value === 'gte' && fi.attr?.[0].value === f[0] // filterText
                      }) || false
                    }
                    onChange={(val) => {
                      // only one can be selected at a time
                      const newFilters = filters.filter((f) => f.prop?.value !== 'cost')
                      if (val) {
                        const ranges = []
                        ranges.push(
                          createFilterFromAttribute('cost', f[0], 'gte', 'int', 'Is greater than'),
                        )
                        if (f[1]) {
                          ranges.push(
                            createFilterFromAttribute('cost', f[1], 'lte', 'int', 'Is less than'),
                          )
                        }
                        setFilters([...newFilters, ...ranges])
                      } else {
                        setFilters(newFilters)
                      }
                    }}
                    label={f.join(' - ')}
                    labelStyle={{ fontSize: '0.75rem', width: '100%' }}
                    containerStyle="w-full"
                  />
                </li>
              )
            })}
          </AccordionItemPanel>
        </AccordionItem>
      )}

      <AccordionItem uuid="inventory">
        <AccordionItemHeading className="transition-colors sticky left-0 top-0 z-10 -ml-1 mb-2 h-6 bg-white duration-200 hover:bg-gray-100">
          <AccordionItemButton className="flex items-center outline-none">
            <ChevronDownIcon className="h-4 w-4" />
            Inventory
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <div className="pl-1 pr-2 text-xs outline-none">
            <CustomPriceRange
              type="inventory"
              hidePrefix={true}
              filters={filters}
              checkedProp={
                !!filters.find((f) => f.operator?.type.includes('custominventory')) || false
              }
              onChange={(val, filterText) => {
                const newFilters = filters.filter(
                  (f) => !f.operator?.type.includes('custominventory'),
                )
                if (val) {
                  // only one can be selected at a time
                  const newFilter = createFilterFromAttribute(
                    'inventoryrange',
                    filterText,
                    'between',
                    'custominventory',
                  )
                  setFilters([...newFilters, newFilter])
                } else {
                  setFilters(newFilters)
                }
              }}
            />
          </div>
          {INVENTORY_FILTERS.map((f, idx) => {
            const filterText = `inventory+${f[0]},${f[1]}`
            return (
              <li
                key={f}
                className="transition-colors flex bg-white py-1 pl-1 text-xs outline-none duration-200 hover:bg-gray-100"
              >
                <Checkbox
                  id={`inventory_${idx}`}
                  checked={!!filters.find((f) => f.attr?.[0].value === filterText) || false}
                  onChange={(val) => {
                    // only one can be selected at a time
                    const newFilters = filters.filter((f) => f.prop?.value !== 'inventoryrange')
                    if (val) {
                      const newFilter = createFilterFromAttribute(
                        'inventoryrange',
                        filterText,
                        'between',
                      )
                      setFilters([...newFilters, newFilter])
                    } else {
                      setFilters(newFilters)
                    }
                  }}
                  label={f.join(' - ')}
                  labelStyle={{ fontSize: '0.75rem', width: '100%' }}
                  containerStyle="w-full"
                />
              </li>
            )
          })}
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem uuid="brands">
        <AccordionItemHeading className="transition-colors sticky left-0 top-0 z-10 -ml-1 mb-2 h-6 bg-white duration-200 hover:bg-gray-100">
          <AccordionItemButton className="flex items-center outline-none">
            <ChevronDownIcon className="h-4 w-4" />
            Brands
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          {brands?.map((b) => (
            <li
              key={b.id}
              className="transition-colors flex bg-white py-1 pl-1 text-xs outline-none duration-200 hover:bg-gray-100"
            >
              <Checkbox
                id={`brand_${b.id}`}
                checked={filters.findIndex((f) => f.attr?.[0].value === b.name) > -1}
                onChange={(val) => {
                  if (val) {
                    const newFilter = createFilterFromAttribute('brand', b.name, 'or')
                    setFilters([...filters, newFilter])
                  } else {
                    const newFilters = filters.filter(
                      (f) => f.prop?.value === 'brand' && f.attr[0].value !== b.name,
                    )
                    setFilters(newFilters)
                  }
                }}
                label={b.name}
                labelStyle={{ fontSize: '0.75rem', width: '100%' }}
                containerStyle="w-full"
              />
            </li>
          ))}
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem uuid="colors">
        <AccordionItemHeading className="transition-colors sticky left-0 top-0 z-10 -ml-1 mb-2 h-6 bg-white duration-200 hover:bg-gray-100">
          <AccordionItemButton className="flex items-center outline-none">
            <ChevronDownIcon className="h-4 w-4" />
            Colors
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          {colors?.map((c) => (
            <li
              key={c.id}
              className="transition-colors flex bg-white px-2 py-1 text-xs outline-none duration-200 hover:bg-gray-100"
            >
              <Checkbox
                id={`color_${c.id}`}
                checked={filters.findIndex((f) => f.attr?.[0].value === c.name) > -1}
                onChange={(val) => {
                  if (val) {
                    const newFilter = createFilterFromAttribute('colors', c.name, 'or')
                    setFilters([...filters, newFilter])
                  } else {
                    const newFilters = filters.filter(
                      (f) => f.prop?.value === 'colors' && f.attr[0].value !== c.name,
                    )
                    setFilters(newFilters)
                  }
                }}
                label={c.name}
                labelStyle={{ fontSize: '0.75rem', width: '100%' }}
                containerStyle="w-full"
              />
            </li>
          ))}
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem uuid="materials">
        <AccordionItemHeading className="transition-colors sticky left-0 top-0 z-10 -ml-1 mb-2 h-6 bg-white duration-200 hover:bg-gray-100">
          <AccordionItemButton className="flex items-center outline-none">
            <ChevronDownIcon className="h-4 w-4" />
            Materials
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          {materials?.map((m) => (
            <li
              key={m.id}
              className="transition-colors flex bg-white px-2 py-1 text-xs outline-none duration-200 hover:bg-gray-100"
            >
              <Checkbox
                id={`material_${m.id}`}
                checked={filters.findIndex((f) => f.attr[0].value === m.name) > -1}
                onChange={(val) => {
                  if (val) {
                    const newFilter = createFilterFromAttribute('material', m.name, 'or')
                    setFilters([...filters, newFilter])
                  } else {
                    const newFilters = filters.filter(
                      (f) => f.prop?.value === 'material' && f.attr[0].value !== m.name,
                    )
                    setFilters(newFilters)
                  }
                }}
                label={m.name}
                labelStyle={{ fontSize: '0.75rem', width: '100%' }}
                containerStyle="w-full"
              />
            </li>
          ))}
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem uuid="tags">
        <AccordionItemHeading className="transition-colors sticky left-0 top-0 z-10 -ml-1 mb-2 h-6 bg-white duration-200 hover:bg-gray-100">
          <AccordionItemButton className="flex items-center outline-none">
            <ChevronDownIcon className="h-4 w-4" />
            Tags
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          {tags?.map((t) => (
            <li
              key={t.id}
              className="transition-colors flex bg-white px-2 py-1 text-xs outline-none duration-200 hover:bg-gray-100"
            >
              <Checkbox
                id={`tag_${t.id}`}
                checked={filters.findIndex((f) => f.attr[0].value === t.name) > -1}
                onChange={(val) => {
                  if (val) {
                    const newFilter = createFilterFromAttribute('tags', t.name, 'or')
                    setFilters([...filters, newFilter])
                  } else {
                    const newFilters = filters.filter(
                      (f) => f.prop?.value === 'tags' && f.attr[0].value !== t.name,
                    )
                    setFilters(newFilters)
                  }
                }}
                label={t.name}
                labelStyle={{ fontSize: '0.75rem', width: '100%' }}
                containerStyle="w-full"
              />
            </li>
          ))}
        </AccordionItemPanel>
      </AccordionItem>

      {isAdmin && !showExclusivesOnly && (
        <>
          <AccordionItem uuid="vendors">
            <AccordionItemHeading className="transition-colors sticky left-0 top-0 z-10 -ml-1 mb-2 h-6 bg-white duration-200 hover:bg-gray-100">
              <AccordionItemButton className="flex items-center outline-none">
                <ChevronDownIcon className="h-4 w-4" />
                Vendors
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {vendors?.map((v) => (
                <li
                  key={v.id}
                  className="transition-colors flex bg-white px-2 py-1 text-xs outline-none duration-200 hover:bg-gray-100"
                >
                  <Checkbox
                    id={`vendor_${v.id}`}
                    checked={filters.findIndex((f) => f.attr[0].value === v.name) > -1}
                    onChange={(val) => {
                      if (val) {
                        const newFilter = createFilterFromAttribute('vendor', v.name, 'or')
                        setFilters([...filters, newFilter])
                      } else {
                        const newFilters = filters.filter(
                          (f) => f.prop?.value === 'vendor' && f.attr[0].value !== v.name,
                        )
                        setFilters(newFilters)
                      }
                    }}
                    label={v.name}
                    labelStyle={{ fontSize: '0.75rem', width: '100%' }}
                    containerStyle="w-full"
                  />
                </li>
              ))}
            </AccordionItemPanel>
          </AccordionItem>

          {!showExclusivesOnly && (
            <AccordionItem uuid="active">
              <AccordionItemHeading className="transition-colors sticky left-0 top-0 z-10 -ml-1 mb-2 h-6 bg-white duration-200 hover:bg-gray-100">
                <AccordionItemButton className="flex items-center outline-none">
                  <ChevronDownIcon className="h-4 w-4" />
                  Active/Inactive
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <li className="transition-colors flex bg-white px-2 py-1 text-xs outline-none duration-200 hover:bg-gray-100">
                  <Checkbox
                    id="active_box"
                    checked={filters.findIndex((f) => f.prop?.value === 'activeStatus') > -1}
                    onChange={(val) => {
                      if (val) {
                        const newFilter = createFilterFromAttribute(
                          'activeStatus',
                          'Inactive',
                          'or',
                        )
                        setFilters([...filters, newFilter])
                      } else {
                        const newFilters = filters.filter((f) => f.prop?.value !== 'activeStatus')
                        setFilters(newFilters)
                      }
                    }}
                    label="Show Inactive"
                    labelStyle={{ fontSize: '0.75rem', width: '100%' }}
                    containerStyle="w-full"
                  />
                </li>
              </AccordionItemPanel>
            </AccordionItem>
          )}
        </>
      )}
      <AccordionItem uuid="dropship">
        <AccordionItemHeading className="transition-colors sticky left-0 top-0 z-10 -ml-1 mb-2 h-6 bg-white duration-200 hover:bg-gray-100">
          <AccordionItemButton className="flex items-center outline-none">
            <ChevronDownIcon className="h-4 w-4" />
            Dropship
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <li className="transition-colors flex bg-white px-2 py-1 text-xs outline-none duration-200 hover:bg-gray-100">
            <Checkbox
              id="dropship_box"
              checked={filters.findIndex((f) => f.prop?.value === 'dropship') > -1}
              onChange={(val) => {
                if (val) {
                  const newFilter = createFilterFromAttribute('dropship', 'Dropship', 'or')
                  setFilters([...filters, newFilter])
                } else {
                  const newFilters = filters.filter((f) => f.prop?.value !== 'dropship')
                  setFilters(newFilters)
                }
              }}
              label="Dropship"
              labelStyle={{ fontSize: '0.75rem', width: '100%' }}
              containerStyle="w-full"
            />
          </li>
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  )
}
